@import "fonts";
@import "variables";

table,
a,
text,
tspan,
h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
li,
span,
button,
.text,
input,
label,
td,
th,
.handle,
[data-id="tooltip"],
.react-confirm-alert-body {
  font-family: $fonts;
  font-weight: 400;

  &.nowrap {
    white-space: nowrap;
  }

  &.align-right {
    text-align: right !important;
  }

  &.align-center {
    text-align: center !important;
  }

  &.align-left {
    text-align: left !important;
  }

  &.bold {
    font-weight: 700;
  }

  &.medium {
    font-weight: 500;
  }

  &.fs-hug {
    font-size: $textHuge;

    .text-image {
      height: $textHuge;
    }
  }

  &.fs-lrg {
    font-size: $textLarge;

    .text-image {
      height: $textLarge;
    }
    svg {
      font-size: $textLarge;
    }
  }

  &.fs-lrg-m {
    font-size: $textLargeMinus;
    line-height: 26px;
    .text-image {
      height: $textLargeMinus;
    }
  }

  &.fs-prc {
    font-size: $textPrice;

    .text-image {
      height: $textPrice;
    }
  }

  &.fs-med {
    font-size: $textMedium;

    .text-image {
      height: $textMedium;
    }
  }

  &.fs-std {
    font-size: $textStandard;

    .text-image {
      height: $textStandard;
    }

    &.same-line-height {
      line-height: $textStandard;
    }
  }

  &.fs-small {
    font-size: $textSmall;
    svg {
      font-size: $textSmall !important;
    }
    .text-image {
      height: $textSmall;
    }
  }

  &.fs-note {
    font-size: $textNote;

    .text-image {
      height: $textNote;
    }
  }

  &.fs-op {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    .text-image {
      height: 18px;
    }
  }

  &.fs-time {
    font-size: 18px;
  }
  &.fs-voucher {
    .text-image {
      height: 25px;
    }
  }

  &.fs-underline {
    text-decoration: underline;
  }

  &.fs-italic {
    font-style: italic;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.c-primary {
    color: var(--primaryText);
  }

  &.c-alternate {
    color: var(--alternateText);
  }

  &.c-orange {
    color: var(--warning);
  }

  &.c-black {
    color: #231f20;
  }

  &.c-gray {
    color: lighten(gray, 10%);
  }
}
