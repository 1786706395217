.voucher-container {
  overflow-y: auto;
  background-color: gray;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/images/voucher/top-1.svg), url(/images/voucher/top-2.svg),
      url(/images/voucher/top-3.svg);
    background-position: 0 0, 0 15px, 0 30px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 1;
  }

  p,
  h1,
  h2,
  h3,
  td {
    font-family: "Montserrat", sans-serif;
  }

  u {
    text-decoration: none;
  }

  .is-bold {
    font-weight: 700;
  }

  .voucher {
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
    position: relative;
    background-image: url(/images/voucher/footer.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: white;

    p {
      &:not(.address) {
        line-height: 20px;
      }
    }
  }

  .voucher-header {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 10px;
    z-index: 10;

    .right {
      padding-right: 40px;
      padding-top: 20px;
    }

    .left {
      width: 200px;
      padding-top: 20px;
      padding-left: 20px;

      img {
        width: 200px;
        height: auto;
        margin-bottom: 5px;
      }

      .address {
        margin-bottom: 10px;
      }

      p {
        color: white;
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }

  .from,
  .to,
  .flight-info {
    width: 170px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }

  .content {
    h1 {
      text-align: center;
      font-size: 16px;
    }

    .qrcode {
      display: block;
      margin: 20px auto;
      height: 120px;
      width: auto;
    }

    .person {
      text-align: center;
      max-width: 500px;
      margin: 0 auto 50px auto;

      h2 {
        margin-bottom: 15px;
        font-size: 13px;
      }

      p {
        font-size: 13px;
      }
    }

    .flight {
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 10px !important;
    }

    &.flight {
      .is-bold {
        color: #6f6f6f;
      }

      .operator {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        font-size: 11px;

        .text-image {
          margin: 0 3px;
          height: 11px;
        }
      }
    }

    .info {
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }

      .title {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        background-color: #e6e7e8;
        padding: 3px 0 3px 35px;
        margin-bottom: 15px;

        img {
          margin-right: 10px;
          width: 35px;
          height: 35px;
        }

        h3 {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .caption {
        > * {
          margin-bottom: 13px;
        }
      }

      .top-caption {
        display: flex;
        justify-content: space-between;

        .supplier {
          font-size: 8px;
          width: 40%;
        }
      }

      .meta,
      .flight,
      .hotel {
        display: -webkit-flex;
        display: flex;
        line-height: 18px;
        margin-right: 35px;
        margin-left: 35px;
      }

      .meta {
        -webkit-justify-content: space-around;
        justify-content: space-around;

        p:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      p {
        font-size: 13px;
      }

      table {
        width: 100%;
      }

      table {
        td {
          vertical-align: top;
          font-size: 13px;
          padding-bottom: 10px;
        }
      }
    }
  }

  .voucher-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 200px;
    color: white;

    img {
      max-height: 13px;
      width: auto;
    }

    p {
      font-size: 9px;
      margin-right: 4px;
    }
  }

  .airplane .general {
    font-size: 14px !important;
    margin-left: 35px;
  }

  .date-line {
    font-size: 15px;
  }

  .hotel .caption {
    margin: 0 30px;
  }
}

.voucher-logo {
  img {
    height: 30px;
    width: auto;
  }
}

.voucher-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }
}

.voucher-airitinprice {
  margin: 0 30px 30px;
  display: flex;
  > * {
    margin-right: 10px;
  }
}
