@import "variables";

.left-align-slick .slick-list > .slick-track {
  margin-left: 0 !important;
}

.react-toggle-track {
  height: 24px !important;
  width: 40px !important;
  background-color: white !important;
}

.react-toggle-thumb {
  outline: 0 !important;
  top: 4px !important;
  left: 4px !important;
  width: 16px !important;
  height: 16px !important;
  border: 0 !important;
  background-color: var(--primary) !important;
  box-shadow: none !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 20px !important;
  border-color: $success !important;
}

.__react_component_tooltip {
  z-index: 999999 !important;
}

.Toastify__toast--info {
  background-color: $info !important;
}

.Toastify__toast--success {
  background-color: $success !important;
}

.Toastify__toast--warning {
  background-color: $warning !important;
}

.Toastify__toast--error {
  background-color: $danger !important;
}
