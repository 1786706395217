.react-tiny-popover-container {
  z-index: 10;
}

.panel-flight-alternative {
  background-color: #ebebeb;
  border: 0;
  margin: 0;
  p {
    margin-bottom: 10px;
    small {
      font-size: 85%;
    }
  }
  p:last-of-type {
    margin: 0;
  }
}

.panel {
  background-color: var(--secondaryBg);
  border-radius: 5px;
  overflow: hidden;
}

.panel-body {
  padding: 15px;
}

.panel-primary {
  > .panel-heading {
    padding: 15px;
    background-color: #081632;
    border-color: #081632;
    color: white;
  }
}

.flight-info-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.flight-alternative {
  border-radius: 5px;
  overflow: hidden;
  transition: transform 200ms ease-in-out;
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
  .flight-alternative-title {
    padding: 5px;
    background-color: var(--primary);
    color: var(--primaryText);
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      font-weight: bold;
    }
  }
  .flight-alternative-info-item {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .flight-alternative-content {
    padding: 5px;
    background-color: white;
  }
  &.selected {
    position: relative;
    z-index: 2;
    transform: scale(1.15);
    .flight-alternative-title {
      background-color: var(--primaryText);
      color: var(--primary);
    }
    .flight-alternative-content {
      background-color: var(--primary);
      color: var(--primaryText);
    }
  }
}
