@import "mixins";

.cols {
  display: flex !important;
  margin: 0 -5px;
  &:not(:last-of-type):not(:first-of-type) {
    margin-bottom: 10px;
  }
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

[class^="col-"],
[class*=" col-"] {
  display: flex !important;
  flex-grow: unset !important;
  flex-shrink: unset !important;
  flex-basis: unset !important;
  flex-wrap: wrap;
  padding: 0 5px;
  &.align-bottom {
    align-self: flex-end;
  }
  > * {
    width: 100%;
  }
}

@include grid-generator(xs, 24);

@include respond-from(sm) {
  @include grid-generator(sm, 24);
}

@include respond-from(md) {
  @include grid-generator(md, 24);
}

@include respond-from(lg) {
  @include grid-generator(lg, 24);
}

@include respond-from(xl) {
  @include grid-generator(xl, 24);
}
