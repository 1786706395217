@import "mixins";
@import "../../node_modules/css-vars/css-vars";
$css-vars-use-native: true;

//$resultBgColor: #58585b;
//$departure: #1B75BC;
//$arrival: #F15A29;
//$checkin: $departure;
//$checkout: $arrival;
//$priceButton: #BE1E2D;
//
//$buyButton: #8DC63F;
//$hotelInfo: #F7941D;
//$basketBg: #59585B;
//$black: #231F20;
//
//$booked: $buyButton;
//$archived: white;
//$ready: $hotelInfo;
//
//$pnrSummaryBand: #ED7055;

$fonts: "Montserrat", sans-serif;

$textHuge: 22px;
$textLarge: 18px;
$textLargeMinus: 17px;
$textPrice: 15px;
$textMedium: 13.5px;
$textStandard: 12px;
$textSmall: 10px;
$textNote: 8px;

$primary: #132744;
$secondary: #18325d;
$primaryBg: #58585b;
$secondaryBg: #ccc;
$danger: #be1e2d;
$warning: #f05a28;
$success: #8cc63e;
$info: #1b75bb;
$primaryText: #fff;
$secondaryText: #1a1a1a;
$alternateText: #fff;

// $primary: #f2f2f2;
// $secondary: #e1e1e1;
// $primaryBg: #b9b9b9;
// $secondaryBg: #fff;
// $danger: #ba4f5d;
// $warning: #eb8266;
// $success: #a2c669;
// $info: #5590b7;
// $primaryText: #575757;
// $secondaryText: #1a1a1a;
// $alternateText: #fff;
$promotion: #ffa931;
$agreement: #fbead6;

:root {
  @include css-vars(
    (
      --primary: $primary,
      --primaryLighten: lighten($primary, 20%),
      --primaryDarken: darken($primary, 20%),
      --secondary: $secondary,
      --primaryBg: $primaryBg,
      --primaryBgLighten: lighten($primaryBg, 20%),
      --primaryBgDarken: darken($primaryBg, 20%),
      --secondaryBg: $secondaryBg,
      --danger: $danger,
      --warning: $warning,
      --success: $success,
      --info: $info,
      --primaryText: $primaryText,
      --secondaryText: $secondaryText,
      --secondaryTextLighten: lighten($secondaryText, 20%),
      --alternateText: $alternateText,
      --blackText: #414042,
      --priceTagText: $secondaryText,
      --promotion: $promotion,
      --agreement: rgba($agreement, 0.8),
      --headerShadow: rgba($primary, 0.4),
      --boxBg: rgba($primaryBg, 0.8),
      --modalContainerBg: rgba($primary, 0.6),
      --modalContainerBgAlternate: rgba($primaryBg, 0.6),
      --priceTagWithOpacity: rgba($danger, 0.5),
      --distance: #608c2e,
    )
  );
}
