@import "mixins";
@import "variables";

button,
.button {
  cursor: pointer;
}

body {
  @include md {
    height: 100vh;
  }
  background-color: white;
}

.pointer {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.is-relative {
  position: relative;
}

//.index-fixer {
//  z-index: 2;
//  pointer-events: none !important;
//  > * {
//    pointer-events: auto;
//  }
//}

.margin-right {
  margin-right: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.layout {
  flex: 1 auto !important;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  pointer-events: none;
  min-height: 100vh;
  @include md {
    height: 100vh;
  }

  > * {
    pointer-events: auto;
  }
}

.container {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  @include md {
    padding: 0 50px;
  }

  &.full-height {
    height: 100vh;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  &.main {
    flex-grow: 1;
    flex-direction: column;
    height: 0;
    @include md {
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.fill-height {
  height: 100%;
}

.results-container {
  padding-top: 100px;
  margin: 20px 0;
  flex-grow: 1;
  //transition: all .5s ease-in;
  // margin-top: 1500px;
}

.results {
  height: 100%;
  //transition: all .5s ease-in;
  flex-wrap: nowrap;

  > .box-container {
    margin-bottom: 20px;
    //&:not(:last-child) {
    //  margin-right: 40px;
    //}
  }
}

.pull-right {
  float: right;
}

.box {
  margin-bottom: 10px;
  background-color: rgba(white, 0.8);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  overflow: hidden;

  &.no-padding-right {
    padding-right: 0;
  }

  &.padding-bottom-10 {
    padding-bottom: 10px;
  }
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.box-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  @include smonly {
    padding-bottom: 40px;
    .pnr-list {
      height: unset !important;
    }
  }
  @include md {
    background-color: var(--boxBg);
    &:not(.doubled) {
      max-width: 40%;
    }
    border-radius: 10px;
  }

  &:not(:last-of-type) {
    margin-right: 40px;
  }

  &.doubled {
    width: 90%;
  }

  .result-list-set {
    flex-wrap: nowrap;
    height: 0;
    flex-grow: 1;
  }

  &:not(.transports) {
    .result-list {
      height: 100% !important;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .result-list {
    &.double-result {
      width: 50%;
    }

    .hotel-container {
      padding: 0 20px;
    }

    h1 {
      color: var(--primaryText);
      font-weight: bold;
      font-size: 17px;
      margin-left: 20px;
      margin-bottom: 10px;
    }

    &.hidden {
      display: none;
    }
  }

  .title {
    padding: 10px 20px;
    flex-shrink: 0;
    @include md {
      padding: 20px;
    }

    > span {
      font-size: 28px;
    }

    h1,
    svg {
      color: var(--alternateText) !important;
    }

    h1 {
      font-size: 13px !important;
      font-weight: 700;
    }

    &.c-orange {
      * {
        color: var(--warning);
      }
    }
  }
}

.box-title {
  margin-bottom: 20px;

  &.has-padding {
    padding: 20px 20px 0 20px;
  }

  span {
    font-size: 28px;
  }

  h1 {
    font-size: 13px !important;
  }
}

.filler {
  flex-grow: 1;
}

.flex {
  display: flex !important;

  &.grow {
    flex-grow: 1;
  }

  &.inline {
    display: inline-flex !important;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap-mobile {
    @include smonly {
      flex-wrap: wrap;
    }
  }

  &:not(.d-column) {
    &.to-right {
      justify-content: flex-end;
    }
  }

  &.d-column-mobile {
    @include smonly {
      flex-direction: column;
    }
  }

  &.d-column {
    flex-direction: column;

    &.to-right {
      align-items: flex-end;
    }
  }

  &.j-around {
    justify-content: space-around;
  }

  &.j-between {
    justify-content: space-between;
  }

  &.j-even {
    justify-content: space-evenly;
  }

  &.j-center {
    justify-content: center;
  }

  &.j-end {
    justify-content: flex-end;
  }

  &.a-center {
    align-items: center;
  }

  &.a-end {
    align-items: flex-end;
  }

  > .fill-space {
    flex-grow: 1;
  }

  > .no-shrink {
    flex-shrink: 0;
  }

  > .self-center {
    align-self: center;
  }

  > .self-end {
    align-self: flex-end;
  }

  > .self-baseline {
    align-self: baseline;
  }
}

.gap-10 {
  > *:not(:last-child) {
    margin-right: 10px;
  }
}

.gap-5 {
  > *:not(:last-child) {
    margin-right: 5px;
  }
}

.is-centered {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.result-header-icon {
  font-size: 48px;
  margin-right: 16px;
}

.button {
  background: none;
  border: none;

  &.is-small {
    font-size: 16px;
  }

  &.is-large {
    font-size: 20px;
  }
}

.bg-image {
  background: no-repeat top;

  &.bg-fullwidth {
    background-size: 100% auto;
  }

  &.bg-cover {
    background-position: center;
    background-size: cover;
  }
}

.bg-flight {
  background: no-repeat 20px;
}

.dates.no-margin-top {
  .date {
    margin-top: -10px;
  }
}

.date {
  width: 35px;
  height: 42px;

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  .day {
    margin-top: 3px;
  }
}

.image {
  &.responsive {
    display: block;
    width: 100%;
    height: auto;
  }
}

.column-gutter {
  > *:not(:last-of-type) {
    margin-right: 10px;
  }
}

.price-tag {
  //width: 100px;
  height: 28px;
  background-color: var(--danger);

  &.right-radius {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.left-radius {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.is-small {
    width: 64px;
    height: 16px;
    font-size: 10px;
  }
}

.right-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button {
  width: 48px;
  height: 28px;

  &.cart {
    background-color: var(--primaryBgDarken);

    //&:active {
    //  background-color: darken(var(--primaryBgDarken), 10%);
    //}
  }

  &.buy {
    background-color: var(--success);

    //&:active {
    //  background-color: darken(var(--success), 10%);
    //}
  }

  &.info {
    margin-top: 20px;
    background-color: var(--warning);
    width: 32px;

    //&:active {
    //  background-color: darken(var(--warning), 10%);
    //}
  }

  &.cancel {
    background-color: var(--danger);
  }

  &.remove {
    width: 32px;
    font-weight: 500;
    background-color: var(--primaryBgDarken);
  }
}

.gutter-bottom {
  margin-bottom: 15px;
}

.gutter-bottom-10 {
  margin-bottom: 10px;
}

.gutter-bottom-3 {
  margin-bottom: 3px;
}

.gutter-top {
  margin-top: 5px;
}

.basket-line {
  position: relative;
  width: 16px;
  overflow: visible;
  margin: 15px 5px 15px 0;
  display: flex;
  align-items: center;

  .line {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    transform: translate(-50%);
  }

  .line-icon {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    z-index: 1;
  }

  .pointer {
    position: absolute;
    left: 5px;
    height: 6.5px;
    width: 6.5px;
    content: "";
    border-radius: 4px;

    &.start {
      top: -3.2px;
    }

    &.finish {
      bottom: -3.2px;
    }
  }
}

.slasher {
  background: linear-gradient(130deg, rgba(153, 218, 255, 0) 45%, rgba(0, 0, 0, 0.7) 45.2%);
}

.slick-list {
  margin: 0 -5px;
}

.slider-container {
  height: 100%;
  width: 100%;

  .slick-slider {
    height: 55px;
  }

  .slick-arrow {
    &:before,
    &:after {
      color: var(--secondaryText);
    }

    &.slick-prev {
      left: 14px;

      &:before {
        content: "\f053";
      }
    }

    &.slick-next {
      right: 14px;

      &:before {
        content: "\f054";
      }
    }
  }
}

.dropdown-title {
  color: var(--primaryText);
  cursor: pointer;

  svg {
    margin-left: 10px;
  }
}

.dropdown {
  cursor: pointer;
  position: relative;
  min-width: 96px;

  h1,
  .dropdown-item {
    color: var(--primaryText);
  }

  h1 {
    display: flex;

    svg {
      margin-left: 10px;
    }
  }

  .dropdown-item-list {
    z-index: 1;
    background-color: var(--primary);
    margin-top: 5px;
    width: 120%;
    position: absolute;
    border: 1px solid white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .dropdown-item {
      padding: 10px;

      &.selected {
        background-color: white;
        color: var(--primary);
      }
    }
  }

  &:not(.active) {
    .dropdown-item-list {
      display: none;
      color: var(--primaryText);

      .dropdown-item {
        &:not(.selected) {
          display: none;
        }
      }
    }
  }
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--primaryText);
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 500;

  span {
    margin-right: 5px;
  }

  &.on-right {
    margin-left: 10px;
  }

  i {
    margin-left: 10px;
    font-size: 16px;
  }
}

.passenger-box {
  position: relative;
  min-width: 120px;

  .counters {
    z-index: 1;
    margin-top: 5px;
    width: 150%;
    position: absolute;
    padding: 10px;
    border: 1px solid white;
    background-color: var(--primary);
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .counter {
      display: flex;
      color: var(--primaryText);

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .counter-name {
        margin-right: 5px;
        display: flex;
        align-items: center;
        width: 40%;
      }

      .counter-controller {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.middot {
  display: flex;
  align-items: center;

  &:after {
    flex-shrink: 0;
    margin: 0 15px;
    content: "·";
  }
}

.is-scrollable {
  flex-grow: 1;
  overflow-y: auto;
}

.table {
  &.fixed-layout {
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
  }

  &.is-fullwidth {
    width: 100%;
  }
}

//.success {
//  color: #0baa00;
//}
//
//.danger {
//  color: #aa0014;
//}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.cell-align-bottom {
  vertical-align: bottom;
}

.clean-button {
  border: none;
  background-color: transparent;
}

.loading {
  &.dimension {
    width: 100% !important;
    height: 100% !important;
  }

  margin: 0 !important;
  padding: 0 !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;

  * {
    font-size: 24px !important;
  }
}

.image-container {
  background-repeat: no-repeat !important;

  &.bc-primary {
    background-color: white !important;
  }

  &.cover {
    background-color: white;
    background-position: center !important;
    background-size: 100% auto !important;
  }

  &.height {
    background-position: left;
    background-size: contain;
  }
}

.separator-bar {
  margin: 0 10px;
  height: 40px;
  border-right: 1px solid white;
}

.bc-orange {
  background-color: var(--warning) !important;
}

.bc-success {
  background-color: var(--success) !important;
}

.bc-danger {
  background-color: var(--danger) !important;
}

.price-tag {
  color: var(--alternateText);
}

.button {
  color: var(--alternateText);
}

strong {
  font-weight: 700 !important;
}

.car-amenity {
  display: flex;
  align-items: center;
  font-size: 10px;

  &:before {
    content: "";
    width: 25px;
    height: 20px;
    background: url(/images/icons/carfeat.png) no-repeat 0 100% hsla(0, 0%, 0%, 0);
  }

  &.door:before {
    background-position: -100px 0;
  }

  &.passenger:before {
    background-position: 0 0;
  }

  &.air-condition:before {
    background-position: -155px 0;
  }

  &.transmission:before {
    background-position: -300px 0;
  }

  &.manual:before {
    background-position: -204px 0;
  }

  &.automatic:before {
    background-position: -250px 0;
  }

  &.luggage:before {
    background-position: -100px 0;
  }
}

a {
  cursor: pointer;
}
