﻿@import "columns";

.admin-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px) !important;
  width: 100%;

  .title {
    margin-bottom: 10px;

    h1 {
      color: var(--primary);
      font-weight: 700;
      font-size: 13px;

      svg {
        margin-right: 5px;
      }
    }

    &.main {
      h1 {
        font-size: 17px;
        color: var(--primaryText);
      }
    }
  }

  .stars {
    display: flex;
  }

  .admin-sub-nav {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    a,
    button {
      display: flex;
      padding: 10px;
      background-color: rgba(88, 88, 91, 0.6);
      text-decoration: none;
      color: var(--primaryText);

      &.active {
        background-color: rgba(88, 88, 91, 0.8);
      }
    }

    li {
      overflow: hidden;

      &:first-of-type {
        border-top-left-radius: 10px;
      }

      &:last-of-type {
        border-top-right-radius: 10px;
      }
    }
  }

  .admin-container {
    width: 100%;
    display: flex;
    overflow: auto;
    flex-grow: 1;

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .admin-aside-menu {
    width: 300px;
    overflow-y: auto;
    padding: 10px;
    flex: 1;
  }

  .admin-content {
    padding: 15px;
    flex: 1;
    overflow-y: auto;
  }
}

.gray-box {
  position: relative;
  background-color: rgba(88, 88, 91, 0.8);
  border-radius: 10px;

  &.ignore-top-left-radius {
    border-top-left-radius: 0;
  }

  &.ignore-top-radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.agreement-hotel-list {
  .agreement-hotel {
    cursor: pointer;
    background-color: var(--primary);
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    display: block;
    text-decoration: none;

    * {
      color: var(--primaryText);
    }

    &.selected {
      background-color: white;

      * {
        color: var(--primary);
        font-weight: 700;
      }
    }
  }
}

.agreement-hotel-add-button {
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
  border: 1px solid var(--primary);
  padding: 5px;
  background-color: white;
  color: var(--primary);
  text-align: center;
  width: 100%;
  font-size: 14px;

  &:hover {
    background-color: var(--primary);
    color: var(--primaryText);
    border-color: white;
  }
}

.is-closed {
  display: none !important;
}

.caret-indicator {
  color: var(--primary);
  width: 10px;
  text-align: center;
}

.form-row {
  &:not(:last-of-type):not(:only-of-type) {
    margin-bottom: 15px;
  }

  > .title {
    * {
      font-size: 18px !important;
    }

    h1 {
      margin-left: 5px;
    }
  }
}

.amenity-checkbox-list {
  display: flex;
  flex-wrap: wrap;

  .amenity-checkbox {
    cursor: pointer;
    padding: 5px;
    border: 1px solid var(--alternateText);
    color: var(--alternateText);
    margin: 0 5px 5px 0;

    &.checked,
    &:hover {
      background-color: var(--secondaryBg);
      color: var(--primaryText);
    }
  }
}

.has-addon {
  display: flex;

  button {
    border: none;
    background-color: var(--success);
    color: var(--primaryText);
    width: 30px;
    flex-shrink: 0;
  }
}

.agreement-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary) !important;
  color: var(--primaryText);
  border: none;
}

.airline-search {
  position: relative;

  ul {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: white;

    li {
      color: black;
      padding: 5px;
      cursor: pointer;

      &:hover {
        color: var(--primaryText);
        background-color: black;
      }
    }
  }
}

.cluded-item {
  margin-right: 10px;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid white;
  cursor: pointer;
  font-weight: 700;
  color: var(--primaryText);

  &:not(.star) {
    padding-bottom: 1px;
  }

  &.is-selected {
    background-color: white;
    color: var(--primaryBg);
  }
}

.policy-type {
  display: flex;

  > span {
    cursor: pointer;
    margin-right: 10px;
    padding: 5px 0;
  }
}

.excluded,
.included {
  margin-bottom: 10px;
}

.form {
  h4 {
    color: var(--primaryText);
    border-bottom: 1px solid white;
    margin-bottom: 5px;
  }

  h5 {
    color: var(--primaryText);
    margin-right: 3px;
  }
}

.time-between {
  padding: 10px;
  border-radius: 10px;
  background-color: white;

  .filter {
    &:last-of-type {
      margin-bottom: 15px;
    }
  }
}

.addon-input {
  height: 26px;
  width: 30px !important;
  border: none;
  border-left: 1px solid var(--primaryBg);
}

.chart-container {
  display: flex;
  align-items: flex-end;

  &:not(.shrinked) {
    flex-shrink: 0;
  }

  &:not(.fill-height) {
    height: 350px;
  }

  width: 100%;

  &.fill-height {
    height: 100%;
  }

  .chart {
    width: 100%;
    height: 100%;
  }
}

.charts {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
}

.rights-menu {
  border: 1px solid white;
  padding: 5px;
  margin: 0 10px 10px 10px;

  p {
    color: var(--primaryText);
  }

  .rights-sub-menu {
    margin-top: 10px;
  }
}

.accounting-action-table {
  width: 500px;
  margin-bottom: 10px;

  thead {
    border-bottom: 1px solid white;
  }

  th,
  td {
    padding: 5px;
  }

  th {
    text-align: center;
  }

  td {
    text-align: right;
  }

  .total-row {
    background-color: #343d44;

    td {
      padding: 10px 0;
      font-weight: bold;
    }
  }
}

.modal-right-button {
  align-self: flex-end !important;
  float: right;
  margin-right: -20px;
}

.invoice-table {
  table {
    width: 100%;
  }

  td {
    padding: 5px;

    input {
      width: 100%;
    }
  }
}

.dashboard {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @include md {
    justify-content: space-between;
  }
  min-height: calc(100% - 40px);
  width: 1400px;
  max-width: 100%;
  flex-wrap: wrap;
  @include smonly {
    padding: 0 10px;
  }

  &.homepage {
    > div {
      height: 400px;
    }
  }

  .dashboard-title {
    width: 100%;

    .location {
      color: var(--secondary);
      display: flex;
      align-items: center;
      height: 40px;

      span {
        font-weight: 700;
      }
    }
  }

  &.world {
    flex-direction: column;
  }

  .row {
    width: 100%;
    display: flex;
    flex-grow: 1;

    &.separator {
      margin-bottom: 30px;
      border-bottom: 2px dashed var(--primaryText);
    }

    &.vertical {
      flex-direction: column;
    }

    > div:not(.table-wrapper):not(.table-search) {
      max-width: 100%;
      @include xs {
        margin: 0 auto 30px auto;
      }
    }
  }

  .graph {
    width: 500px;
    margin-bottom: 80px;

    h1 {
      text-align: center;
      font-size: 25px;
      @include md {
        margin-top: -50px;
      }
      margin-bottom: 30px;
      color: var(--blackText);
    }
  }

  .expenses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .expense-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &.has-icon {
        .icon {
          width: 70px;
          text-align: right;
          color: var(--blackText);
          font-size: 50px;
          margin-right: 30px;
        }
      }
    }

    .content {
      color: var(--blackText);

      h1 {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 10px;
      }

      button {
        color: var(--warning);
        font-size: 10px;
      }
    }
  }

  .total {
    display: flex;
    align-items: center;
    font-size: 20px;

    p {
      margin-left: 15px;
      font-weight: 300;
      font-size: 30px;
    }
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-y: auto;
}

.spent-table {
  color: var(--blackText);
  margin-left: -10px;

  table {
    height: calc(100% - 160px);
  }

  h1 {
    margin-bottom: 10px;
  }

  td {
    vertical-align: middle;
  }

  .spent-title {
    font-weight: 700;
    font-size: 14px;
    width: 150px;
  }

  .spent-value {
    font-size: 20px;

    span {
      font-size: 30px;
    }
  }

  .total {
    font-size: 30px;

    p {
      font-size: 50px;
    }
  }
}

.spent-report-title {
  align-self: flex-start;
  font-size: 20px;
  margin-bottom: 10px;
}

.spent-report {
  thead {
    background-color: var(--primary);
    color: var(--primaryText);

    th {
      text-align: left;
      vertical-align: middle;
      padding: 5px;
    }
  }

  tbody {
    tr {
      &:not(:last-of-type) {
        border-bottom: 1px dashed gray;
      }

      &.last-row {
        border-bottom: double black 3px;
      }
    }

    td,
    a {
      text-decoration: none;
      color: black;
      font-weight: 700;
      text-align: right;
      padding: 8px 5px;
    }
  }

  .fixed-width {
    width: 140px;
  }
}

.report-placeholder {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    width: 475px;
    height: 259px;
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }
}

.policy-title {
  color: var(--primaryText);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.page-title {
  text-align: center;
  margin: 30px 0;

  h1 {
    font-size: 30px;
  }
}

.graphs {
  margin-bottom: 100px;
}

.employee-spent-table {
  table {
    td,
    th {
      text-align: right;
      font-size: 18px;
    }

    thead {
      background-color: var(--primary);
      color: var(--primaryText);
      font-weight: 700;

      th {
        padding: 10px;
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;
        }

        &:hover {
          background-color: var(--primaryBgDarken);

          td {
            color: var(--alternateText);
          }
        }
      }
    }
  }
}

.employee-spent-summary {
  flex-shrink: 0;

  td {
    padding: 10px;
    vertical-align: middle;
    font-size: 20px;
  }

  .employee-spent-big {
    font-size: 50px;
  }
}

.spent-breadcrumb {
  margin-left: 10px;

  * {
    color: var(--secondary);
  }

  .breadcrumb-separator {
    padding: 0 5px;

    &:after {
      content: "/";
    }
  }
}

.no-wrap {
  * {
    white-space: nowrap;
  }

  white-space: nowrap;
}

.sticky-table {
  .sticky-table-cell {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 10px;
    vertical-align: middle;
    background-color: transparent;
    border: none !important;
  }

  .sticky-table-row {
    &:first-of-type {
      .sticky-table-cell {
        &:first-of-type {
          text-align: center;
        }

        span {
          font-weight: 700;
        }

        background-color: var(--primary);
        color: var(--primaryText);
      }
    }

    &:not(:first-of-type) {
      &:hover {
      }

      .sticky-table-cell {
      }
    }
  }
}

.cell-center {
  text-align: center;
}

.report-table {
  .commission {
    background-color: #fcffdc;
    font-weight: bold;
  }

  &.striped {
    .sticky-table-row:not(.total-row) {
      &:nth-of-type(2n) {
        background-color: lightgrey !important;

        .commission {
          background-color: darken(#fcffdc, 10%);
        }
      }

      &:hover:not(:first-of-type) {
        background-color: gray !important;

        .commission {
          background-color: darken(#fcffdc, 70%);
        }
      }
    }
  }

  .total-row {
    background-color: #6988bd !important;
    color: var(--primaryText) !important;

    > div {
      * {
        font-size: 12px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }
}

.accounting-table {
  .is-collected.is-costinvoiced {
    background-color: mediumspringgreen !important;

    &.row-checked,
    &:hover {
      background-color: #00e181 !important;
    }
  }

  .is-collected:not(.is-costinvoiced) {
    background-color: lightskyblue !important;

    &.row-checked,
    &:hover {
      background-color: #72b5e1 !important;
    }
  }

  .is-invoiced.is-costinvoiced:not(.is-collected) {
    background-color: #ffc24e !important;

    &.row-checked,
    &:hover {
      background-color: #e1a749 !important;
    }
  }

  .is-invoiced:not(.is-costinvoiced):not(.is-collected) {
    background-color: #fff674 !important;

    &.row-checked,
    &:hover {
      background-color: #e1d86e !important;
    }
  }

  .sticky-table-row:not(.is-invoiced) {
    background-color: white !important;

    &.row-checked,
    &:hover {
      background-color: #e1e1e1 !important;
    }
  }
}

#export-table {
  display: none !important;
}

.tab-is-hidden {
  display: none;
}

.tab-link {
  &.tab-is-active {
    button,
    a {
      background-color: hsla(240, 2%, 35%, 0.8);
    }
  }
}

.agreement-input-label {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: var(--primaryText);
  margin-bottom: 0;
  align-items: stretch;
  flex-grow: 1;
  input,
  textarea {
    color: var(--primaryText);
    background: rgba(255, 255, 255, 0);
    border-bottom: 1px solid var(--primaryText) !important;
  }
  select {
    color: var(--primaryText);
    border: none;
    border-bottom: 1px solid var(--primaryText) !important;
  }
  input,
  textarea,
  select {
    &::placeholder {
      color: lightgray;
    }
    background-color: gray;
  }
}

.input-helper {
  font-weight: bold;
  font-size: 10px;
  margin-top: 3px;
  &.danger {
    color: var(--danger);
  }
}

.geocode-selector {
  position: absolute;
  top: 40px;
  z-index: 10;
  li {
    font-size: 13px;
    padding: 5px 10px;
    background-color: lightgray;
    color: var(--primary);
    cursor: pointer;
    &:hover {
      color: lightgray;
      background-color: var(--primary);
    }
  }
}
//- isCollected =TRUE ve isCostInvoice =TRUE ise  (Green )
//- isCollected =TRUE , isCostInvoice =FALSE ise (Blue)
//- isCollected =FALSE, isInvoiced =TRUE ve isCostInvoice =TRUE ise (Orange)
//- isCollected =FALSE, iInvoice =TRUE ve isCostInvoice =FALSE ise (Yellow)
//- isInvoice = FALSE ise diğerleri önemsiz (Pink /Somon)
