.modal-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: var(--primaryText);
}
.car-module {
  width: 800px;
  .inline-search {
    input {
      color: var(--primaryText);
      border-radius: 10px;
      border: 1px solid var(--primaryText);
      background-color: transparent;
    }
  }
}

.box-container {
  &.transports {
    max-width: calc(100% + 40px) !important;
    width: calc(100% + 40px) !important;
    margin-bottom: -20px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: transparent;
    > .title {
      * {
        color: var(--primaryText) !important;
      }
    }
  }
}

.formik-form {
  display: flex;
  margin-right: -20px;
  margin-bottom: 40px;
  input,
  select {
    font-size: 14px;
  }
}
.width-175 {
  width: 175px;
}
.formik-input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
  &.fill {
    flex-grow: 1;
  }
  button {
    padding: 5px;
    margin-left: 30px;
  }
}
.formik-input-group-content {
  display: flex;
}

.formik-input-group-label {
  margin-bottom: 3px;
  label {
    color: var(--primaryText);
    //font-weight: bold;
  }
}

.formik-time-select {
  position: absolute;
  right: 0;
  margin: 5px;
  select {
    color: var(--primaryText);
    background-color: var(--primary);
    border-radius: 10px;
    border: 1px solid var(--primaryText);
  }
}
.formik-input {
  flex-grow: 1;
  overflow: hidden;
  border: 1px solid var(--primaryText);
  .has-dropdown {
    position: relative;
    overflow: visible;
  }
  input {
    color: var(--primaryText);
    padding: 5px 10px;
    border: none;
    width: 100%;
    background-color: var(--primary);
    &::placeholder {
      color: #9c9c9c;
    }
  }
  border-radius: 10px;
}

.no-shrink {
  flex-shrink: 0;
}
.formik-input-gutter {
  flex-grow: 1;
  min-width: 60px;
}
.formik-location-picker {
  z-index: 1;
  position: absolute;
  background-color: var(--primary);
  border: 1px solid var(--primaryText);
  ul {
    li {
      white-space: nowrap;
      padding: 5px;
      color: var(--primaryText);
      &:not(.location-picker-title) {
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          color: var(--primary);
          background-color: var(--primaryText);
        }
      }
      &.location-picker-title {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
.modal-title {
  h1 {
    color: var(--primaryText);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.padding-fix {
  padding: 0 20px;
  > div {
    position: relative;
  }
}

.car-search-button {
  font-size: 20px;
  border: none;
  background-color: transparent;
  color: var(--primaryText);
}
