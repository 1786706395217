@import "mixins";
@import "variables";
@import "elements";
@import "reset";
@import "hotels";
@import "typography";
@import "images";
@import "overrides";
@import "admin";
@import "header";
@import "columns";

.none {
  display: none !important;
}

.dates {
  .date {
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
    }

    span {
      z-index: 0;
    }
  }
}

.flight-container {
  padding: 0 20px;
  .operators {
    height: 100%;
    flex-shrink: 0;
    margin-right: 0;

    .operator-airline {
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
  &.detailed {
    .extensible {
      padding: 10px;
      margin: -10px;
      background-color: white;
    }
  }

  .controllers {
    width: 72px;
  }

  .flight {
    overflow: visible;
    position: relative;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: darken(white, 10%);
    }

    .destination {
      min-height: 100px;
      transition: min-height 0.2s ease-in-out;

      .route-container {
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
      }
    }

    .detailed-footer {
      padding: 0 20px;
      margin-bottom: 10px;
    }

    .detail-indicator {
      position: absolute;
      bottom: 0;
      right: 5px;
    }

    &.detailed {
      .destination {
        min-height: 150px;
      }
    }
  }
}

.hotel-container {
  .hotel {
    width: 100%;

    .info {
      display: flex;
      flex-direction: column;
      line-height: 18px;
    }

    .dates {
      margin-top: 30px;
      margin-right: 20px;
    }
  }
}

.promotion {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: var(--promotion);
}

.flight {
  .destination {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

.route {
  position: relative;

  .time-container {
    svg {
      margin: 0 40px;
    }

    position: relative;

    .time {
      position: absolute;
      bottom: 40%;
      left: 25px;
      right: 25px;
    }
  }

  .route-delete {
    position: absolute;
    right: -27px;
    align-items: center;
    height: 100%;
    display: flex;

    button {
      color: var(--alternateText);
      background: none;
      border: none;
      font-size: 16px;
    }
  }

  .location {
    margin: 0 20px;

    .from,
    .to {
      width: 50%;
    }

    .to {
      text-align: right;
    }
  }

  p.elapsed {
    text-align: center;
    color: var(--secondaryText);
  }
}

.source-note {
  position: absolute;
  bottom: 2px;
  left: 10px;
  line-height: 1 !important;
}

.controllers {
  padding-top: 10px;

  > button,
  > p,
  > div:not(.modal-wrapper) {
    overflow: hidden;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--alternateText);

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  button,
  > div:not(.modal-wrapper) {
    display: block !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  > div:not(.modal-wrapper) {
    display: inline-block;

    * {
      color: var(--alternateText);
    }
  }
}

.filtered-airports {
  &.hidden {
    display: none;
  }
}

.hotel-container {
  .controllers {
    width: 72px;
    flex-shrink: 0;
  }

  &.agree {
    .box {
      background-color: var(--agreement);
    }
  }

  .hotel {
    .image-container {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 10px;
      background-position: center;
      background-size: cover;
    }

    .card {
      margin-bottom: 10px;
    }

    .rooms {
      width: 100%;

      .load-rooms {
        width: 100%;
        height: 40px;
        font-size: 16px;
        background-color: var(--success);
        color: var(--primaryText);
      }
    }
  }
}
.room-slider {
  margin-bottom: 10px;

  .slick-dots {
    bottom: -17px;
  }

  .room {
    cursor: pointer;
    position: relative;
    height: 50px;
    background: repeating-linear-gradient(-45deg, #3a3a3c, #3a3a3c 5px, #474749 5px, #474749 10px);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 5px;
    padding-left: 10px;

    .cancellation-policy-button {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 5px;
      background-color: var(--danger);
    }

    &.selected {
      border: 2px solid var(--danger);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.82);
    }

    .caption {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .price {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: var(--danger);
        color: var(--alternateText);
        padding: 5px 5px;
        margin-left: 5px;
        width: 60px;
        text-align: center;
        flex-shrink: 0;
      }

      .type {
        flex-grow: 1;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
.cancellation-policy {
  margin: 0 20px 10px 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--primaryText);

  p {
    margin-bottom: 5px;
  }
}

.basket {
  z-index: 10;
  bottom: 1120px;
  position: absolute;
  overflow: hidden;
  max-height: 100vh;
  transition: bottom 0.5s ease-in;
  display: flex;

  .basket-content {
    background-color: var(--primary);
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 340px;
  }

  .basket-header {
    flex-shrink: 0;

    *:not(select) {
      color: var(--primaryText);
    }
  }

  .basket-footer {
    display: flex;
    align-items: center;
    height: 56px;
    background-color: var(--danger);
    flex-shrink: 0;

    .basket-total {
      font-size: 19px;
      color: var(--alternateText);
      flex-grow: 1;

      p {
        text-align: right;

        .value {
          margin-left: 10px;
          font-weight: 700;
        }
      }
    }

    .basket-confirm {
      width: 32px;
      height: 100%;

      > button {
        background: none !important;
      }

      button {
        font-size: 32px;
        height: 100%;
        color: var(--alternateText);
        border: none;
        display: inline-block;
      }
    }
  }

  .basket-items {
    overflow-y: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > div {
      flex-grow: 1;
    }
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    margin-top: 0;
  }

  .box-title {
    span {
      font-size: 24px;
    }
  }

  //&.hidden {
  //  display: none;
  //}
  .title {
    padding: 20px;
  }

  .box {
    margin: 0 10px 10px 10px;

    .info {
      margin: 20px 0;
      padding-left: 5px;
    }
  }

  .right-side {
    justify-content: space-between;

    .price-tag {
      align-self: flex-end;
      width: 80px;
    }
  }

  .hotel {
    .info {
      .bg-image {
        width: 28px;
        height: 28px;
        margin-right: 3px;
      }
    }
  }

  .actions {
    padding: 0 20px 20px 20px;

    button {
      font-size: 18px;
      background: none;
      border: none;
      color: var(--alternateText);
      @include spacer(24px);
    }
  }

  .specials {
    .slide {
      position: relative;
      width: 100%;
      height: 128px;
      background-color: var(--primaryText);

      .bg-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background: no-repeat 10px center;
      }

      .content {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          height: 21px;
          width: auto;
          margin-left: 3px;
        }

        .info {
          p {
            line-height: 10px;
          }
        }
      }
    }
  }
}

.slide {
  height: 200px;
}

.payment-switch {
  padding: 10px;
  width: 160px;

  &.payment {
    background-color: var(--primaryBg);
    padding: 5px 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .react-toggle-thumb {
      background-color: var(--primaryBg) !important;
    }

    margin-bottom: 10px;
  }

  > p {
    color: var(--primaryText);
    font-size: 9px;
    margin-bottom: 10px;
  }

  .react-toggle {
    margin: 0 5px;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 9px;
      color: var(--alternateText);
      //width: calc(100% / 3);
      &.is-bold {
        font-weight: 700;
      }
    }
  }
}

.slick-dots {
  bottom: 5px;

  li {
    margin: 0;

    button:before {
      color: var(--primaryText);
      opacity: 1;
    }

    &.slick-active {
      button:before {
        color: var(--secondaryText);
      }
    }
  }
}

.hotel-container {
  width: 100%;
}

.has-dropdown {
  .dropdown-links {
    z-index: 1;
    display: none;
    position: absolute;
    background-color: var(--primaryBg);
    padding-top: 10px;

    &:hover {
      display: block;
    }

    li {
      a {
        display: block;
        padding: 5px 10px;
        color: var(--primaryText);
        text-decoration: none;
        font-size: 13px;
        letter-spacing: 0.3px;

        &:hover {
          letter-spacing: 0;
          font-weight: bold;
        }
      }
    }
  }

  &:hover {
    .dropdown-links {
      display: block;
    }
  }
}

.external-service {
  cursor: pointer;
  text-align: left;
}

button.submit {
  color: var(--alternateText);
  width: 48px;
  font-size: 16px;
  border: none;
  background-color: var(--success);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.airport-list {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 56px;
  padding: 10px 0;
  background-color: var(--primary);
  max-height: 400px;
  overflow-y: auto;

  li {
    color: var(--primaryText);
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;

    &:hover:not(.search-title),
    &.selected {
      cursor: pointer;
      color: var(--primary);
      background-color: var(--primaryText);
    }
  }

  .search-title {
    padding: 5px 15px;
    font-size: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      font-size: 15px;
    }
  }

  &.hotel-picker {
    li:not(.search-title) {
      margin-left: 25px;
    }
  }
}

.basket-toggle-button {
  cursor: pointer;
  border: none;
  background: none;
  color: var(--primary);
  font-size: 32px;
  position: absolute;
  bottom: -40px;
}

.modal-container {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--modalContainerBg);

  &:not(.no-padding) {
    .modal {
      padding: 20px;
    }
  }

  &.no-padding {
    .modal-dismiss {
      border-radius: 0 !important;
      z-index: 2;
    }
  }

  .modal {
    max-height: 95vh;
    overflow: hidden;
    min-width: 100%;
    @include md {
      min-width: 400px;
    }
    background-color: var(--primary);
    color: var(--primaryText);
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;

    > *:not(:last-child) {
      margin-bottom: 20px;
    }

    &.bordered {
      box-shadow: 0 0 5px 1px white;

      .modal-dismiss {
        box-shadow: 0 0 5px 1px white;
      }
    }

    .modal-dismiss {
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 20px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary);
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
    }

    .modal-footer {
      flex-shrink: 0;
    }

    .modal-body {
      flex-grow: 1;
      overflow: auto;
      padding-right: 20px;
      padding-bottom: 5px;
    }
  }

  &.gray {
    //background-color: rgba(88, 88, 91, 0.6);
    background-color: var(--modalContainerBgAlternate);

    > .modal {
      //background-color: rgb(88, 88, 90);
      background-color: var(--secondaryBg);

      .modal-dismiss {
        //background-color: rgb(88, 88, 90);
        background-color: var(--secondaryBg);
      }
    }
  }

  &.full-screen {
    > .modal {
      width: 95vw;
      height: 95vh;

      .modal-body {
        height: 100%;
      }
    }
  }

  &:not(.no-geo) {
    .modal-body {
      margin-right: -20px;
    }
  }

  &.no-geo {
    .modal {
      width: unset !important;
    }
  }
}

.modal-wrapper,
.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--modalContainerBg);

  .confirm-box {
    &.bordered {
      box-shadow: 0 0 5px 1px white;
    }
  }

  .modal {
    min-width: 360px;
    background-color: var(--primary);
    color: var(--primaryText);
    padding: 20px 0;
    position: relative;

    &.width-500 {
      max-width: 500px;
    }

    &.bordered {
      box-shadow: 0 0 5px 1px var(--primaryDarken);
    }

    .modal-header {
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 18px;
      }

      a {
        font-size: 30px;
        cursor: pointer;
      }
    }

    &.full-padding {
      padding: 20px;
    }

    .modal {
      margin: 0 20px;
    }
  }
}

.slider-container .slick-arrow.slick-prev:before {
  content: "◀";
}

.slider-container .slick-arrow.slick-next:before {
  content: "▶";
}

.amenities {
  margin-bottom: 10px;

  &.padding-side {
    padding: 0 30px;
  }

  svg {
    margin: 3px;
  }

  .amenity {
    width: 33%;

    display: flex;
    align-items: center;

    * {
      color: #231f20;
    }
  }
}

.amenity-icon {
  display: inline-block;
  text-align: center;
  width: 14px;
  font-size: 12px;
  margin-right: 5px;

  + span {
    font-size: 8px;
  }
}

.basket-is-active {
  &.basket {
    bottom: 0;
  }
}

.filter-button {
  position: relative;
  overflow: visible;
  width: 30px;
}

.filter-box {
  position: absolute;
  width: 100%;
  right: 0;
  top: 68px;
  z-index: 10;
  background-color: #e6e7e8;
  box-shadow: 0 6px 12px -7px rgba(0, 0, 0, 0.75);

  .filter-group {
    padding: 10px 50px;

    &.inline {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .filter-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pointer {
      font-size: 14px;
    }
  }

  .filter-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px 10px;

    .filter-done {
      * {
        color: var(--primaryText);
      }

      background-color: var(--success);
      border: none;
      font-size: 22px;
      padding: 3px 15px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .filter-reset {
      border: 0;
      background-color: transparent;
    }
  }

  .filter-radio-element {
    display: flex;
    align-items: center;

    span {
      font-size: 18px;

      &.active {
        text-shadow: 1px 0 0 var(--primaryBg);
      }
    }

    .react-toggle {
      margin: 0 3px;

      .react-toggle-track {
        background-color: var(--primaryBg) !important;
      }

      .react-toggle-thumb {
        background-color: var(--primaryText);
      }
    }
  }

  .filter-select-element {
    .select-title {
      font-weight: bold;
      font-size: 16px;
      margin-left: 2px;
    }

    .options {
      height: 55px;
      width: 200px;
      overflow: hidden;

      .option {
        padding: 1px 2px;
        cursor: pointer;
        color: var(--secondaryText);
        border-radius: 10px;
        font-size: 12px;
        margin-right: 10px;

        &.selected,
        &:hover {
          background-color: var(--primaryBg);
          color: var(--primaryText);
        }

        &:hover {
          background-color: var(--primaryBgLighten);
        }
      }
    }
  }

  //border-radius: 10px;
}

.stars {
  span {
    display: block;
    font-size: 20px;
    cursor: pointer;
  }
}

.filter {
  &:not(.label-top) {
    padding: 25px 30px 15px 20px;
    display: flex;
    align-items: center;

    .filter-title {
      color: var(--blackText);
      margin-right: 15px;
      width: 160px;
      text-align: right;
      flex-shrink: 0;

      &.self-flex-start {
        align-self: flex-start;
      }
    }
  }

  &.label-top {
    padding: 0 10px;
    margin-bottom: 25px;

    .filter-title {
      margin-left: -10px;
      display: block;
      margin-bottom: 5px;
    }
  }

  .filter-title {
    font-size: 18px !important;
  }

  .filter-sticker {
    position: relative;
    height: 15px;
    width: 15px;
    border-radius: 100%;

    span {
      width: 100px;
      text-align: center;
      font-size: 13px;
      color: var(--secondaryText);
      position: absolute;
      top: -15px;
      left: -40px;
    }
  }

  p {
    color: var(--primaryText);
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.slider {
  //margin: 0 30px;
  height: 5px;
  background-color: var(--primaryBg);
  border-radius: 10px;
  flex-grow: 1;
  box-shadow: 0 12px 0 0 rgba(0, 0, 0, 0.1);

  .handle {
    box-shadow: 0 12px 0 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: var(--primaryBg);
    height: 16px;
    width: 16px;
    border-radius: 16px;
    top: -6px;
    color: var(--primaryText);
  }
}

.smart-packages-wrapper {
  margin-bottom: 20px;
  width: 100%;

  .smart-packages {
    position: relative;
    padding: 20px;
    background-color: var(--boxBg);
    border-radius: 10px;
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .smart-close {
      position: absolute;
      top: -15px;
      right: -15px;
      border: 0;
      background-color: var(--primaryBg);
      width: 30px;
      height: 30px;
      border-radius: 20px;
      color: var(--primaryText);
    }

    .package {
      position: relative;
      width: 32%;
      max-width: 380px;
      min-height: 210px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      &:not(.transparent) {
        background-color: var(--primaryBg);
      }

      .title {
        padding: 10px;
        color: var(--primaryText);
        display: flex;
        justify-content: space-between;

        .add-basket {
          position: absolute;
          right: 0;
          background-color: var(--primaryBg);
          border: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          color: var(--primaryText);
          top: 55px;
          width: 50px;
          height: 25px;
          font-size: 15px;
        }

        .price-tag {
          margin-right: -10px;
          margin-top: 5px;
          height: unset;
          padding: 5px 10px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }

      .body {
        .flight {
          width: 70%;
        }

        .hotel {
          width: 100%;
          padding-left: 35px;

          .hotel-thumb {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.3);
            margin-right: 5px;
            background-position: center;
            background-size: cover;
          }
        }

        .flight,
        .hotel {
          margin: 0 10px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.pnr-wrapper {
  position: absolute;
  top: 112px;
  left: 0;
  right: 0;
  height: calc(100% - 127px);

  .container {
    height: 100%;
  }

  .box-container {
    max-width: 600px;
  }
}

.pnr-list {
  height: 100%;
  overflow-y: auto;
  > div {
    margin-top: 10px;
    position: relative;
  }
  .pnr {
    margin: 0 20px 10px 20px;
    @include smonly {
      margin: 0 10px;
      height: calc(100% - 10px);
    }
    padding: 10px;
    background-color: var(--primary);
    border-radius: 10px;
    overflow: hidden;
    background-clip: padding-box;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 157px;

    &.flight {
      background-image: url(/images/badges/flight.svg);
    }

    &.hotel {
      background-image: url(/images/badges/hotel.svg);
    }

    &.activity {
      background-image: url(/images/badges/activity.svg);
    }

    &:hover {
      background-color: var(--primaryDarken);
      border-color: var(--primaryDarken);
    }

    &.selected {
      //border-color: #18325D;
      transform: scale(1.05);
      @include md {
        transform: scale(1.02);
      }
      box-shadow: 0 0 5px 4px rgba(255, 255, 255, 1);
    }
  }

  .pnr-content {
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    @include smonly {
      flex-wrap: wrap;
    }

    p {
      color: var(--primaryText);
    }

    > *:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .corp-seal {
      align-self: center;
      @include smonly {
        min-width: 140px;
      }

      img {
        display: block;
        width: auto;
        height: 50px;
        opacity: 0.3;
      }
    }
  }

  .pnr-footer {
    .timestamp {
      color: var(--primaryText);
      font-size: 9px;
      line-height: 1;
      align-self: flex-end;
    }
  }

  .customer-limit {
    p {
      color: var(--primaryText);
      font-size: 12px;
    }

    display: flex;
    align-items: flex-end;
  }

  .pnr-title {
    margin-bottom: 15px;
    color: var(--primaryText);
    @include smonly {
      font-size: 12px;
    }

    &.booked {
      .pnr-status {
        color: var(--success);
      }
    }

    &.archived,
    &.pending,
    &.archive {
      .pnr-status {
        color: var(--primaryText);
      }
    }

    &.ready {
      .pnr-status {
        color: var(--warning);
      }
    }

    &.notticketed {
      .pnr-status {
        color: var(--primaryBg);
      }
    }

    &.ticketed,
    &.confirmed {
      .pnr-status {
        color: var(--success);
      }

      //&:hover {
      //  background-color: darken(var(--success), 10%);
      //}
    }

    &.cancelled {
      .pnr-status {
        color: var(--danger);
      }

      //&:hover {
      //  background-color: darken(var(--danger), 10%);
      //}
    }
  }
}

.passenger-list {
  flex-shrink: 0;
  line-height: 20px;
}

.pnr-details {
  .sub-title {
    @include smonly {
      * {
        font-size: 12px;
      }
    }
  }

  .title {
    padding: 0;
  }

  padding: 10px;
  margin: 0 10px 10px 10px;
  border-radius: 10px;

  @include smonly {
    background-color: var(--primaryBg);
  }
  @include md {
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 10px 0;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .pnr-status {
    .notticketed {
      font-weight: bold;
      color: var(--primaryBg);
    }

    .ticketed,
    .confirmed {
      font-weight: bold;
      color: var(--success);
    }

    .cancelled {
      font-weight: bold;
      color: var(--danger);
    }
  }

  .box {
    padding: 15px;

    .info {
      line-height: 23px;
    }
  }

  .pnr-details-footer {
    display: flex;

    > *:not(:last-child) {
      margin-right: 10px;
    }

    button {
      color: var(--alternateText);
      background-color: var(--primary);
      font-weight: 700;
      padding: 0 10px;
      border: 0;
      height: 32px;
      border-radius: 5px;

      &:hover {
        background-color: var(--primaryDarken);
      }
    }
  }
}

.pnr-details-hotel {
  margin-bottom: 10px;

  .info {
    line-height: 20px;
  }

  .dates {
    margin: 5px;
  }
}

.pnr-details-footer,
.order-action-buttons {
  display: flex;

  .order-total,
  .order-budget {
    height: 40px;

    * {
      font-size: 20px;
      color: var(--alternateText);
      font-weight: bold;
    }

    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .order-total {
    margin-right: -20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--danger);
    color: var(--alternateText);
    font-weight: 700;
  }

  .order-budget {
    margin-left: -20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--success);
  }

  button {
    color: var(--alternateText);
    background-color: var(--primary);
    font-weight: 700;
    padding: 0 10px;
    border: 0;
    height: 32px;
    border-radius: 5px;

    &:hover {
      background-color: var(--primaryDarken);
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.order-action-buttons {
  margin: 10px 20px;

  button {
    width: 150px;
  }
}

.pnr-flight {
  background-color: var(--warning);
  padding: 10px;
  border-radius: 10px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &.ticketed {
    background-color: var(--success);
  }

  &.cancelled {
    background-color: var(--danger);
  }
}

.pnr-flight-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  .route {
    width: 72px;
    display: flex;
    justify-content: space-between;
  }
}

.pnr-customer-list {
  @include smonly {
    overflow-y: scroll;
  }

  .pnr-customer {
    display: flex;

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.pnr-hotel-info {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.direct-booking-box {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--success);

  > p {
    padding: 0 10px;
    color: var(--primaryText);
  }

  .pay-button {
    font-size: 32px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    top: 2px;
    border: 0;
    //border-right: 2px solid var(--primaryText)Color;
    width: 40px;
    color: var(--alternateText);
    background-color: var(--danger);
  }
}

.search-history-list {
  position: absolute;
  background-color: var(--primary);
  left: -20px;
  margin-top: 5px;
  width: 500px;
  border: 1px solid var(--primaryText);
  z-index: 3;

  p {
    padding: 10px;

    &:hover {
      background-color: var(--primaryText);
      color: var(--primary);
    }
  }
}

.credit-card-screen {
  width: 250px;
  padding: 15px 15px 15px 0;
  flex-shrink: 0;

  .cc-box {
    > div {
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .cc-input {
    border-radius: 5px;
    border: 1px solid var(--primaryText);
    padding: 10px;
    width: 100%;
  }

  input {
    border: 0;
    font-size: 15px;
    color: var(--primaryText);
    display: block;
    width: 100%;
    background-color: transparent;
    //&::placeholder {
    //color: var(--primaryText)Color;
    //}
  }

  .cc-sub-info {
    display: flex;
    justify-content: space-between;

    > * {
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
}

.payment-body {
  .item-icon {
    font-size: 25px;
    width: 30px;
    text-align: center;
  }

  .contents {
    margin: 0 5px;

    .content {
      justify-content: space-between;
      display: flex;
      height: 40px;

      &.bottom {
        align-items: flex-start;
      }
    }
  }
}

.payment-screen {
  padding: 0;
  width: 100%;

  .other-item {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  &.cc-active {
    width: 610px;
  }

  hr {
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
  }

  .customers {
    max-width: 360px;
    font-size: 14px;
  }

  .payment-title {
    margin-bottom: 20px;

    h2 {
      font-size: 22px;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        margin-left: 10px;
      }
    }

    a {
      font-size: 30px;
    }
  }

  .policies {
    margin: 0 10px;
    align-items: center;
    font-weight: 300;
    font-style: italic;
    display: flex;
  }

  .payment-footer {
    margin-right: -20px;
    margin-left: -20px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .payment-price {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    background-color: var(--danger);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: var(--alternateText);

    span {
      &:first-of-type {
        font-size: 10px;
      }

      &:last-of-type {
        font-size: 14px;
      }

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  .pay-button {
    background-color: var(--success);
    border: none;
    color: var(--alternateText);
    font-size: 22px;
    padding: 3px 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &:disabled {
      cursor: not-allowed;
      background-color: #6f6f6f;
    }
  }
}

.room-select {
  width: 75px;
  margin-left: 10px;
}

.reports {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .report-page-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 15px;
    text-decoration: underline;
  }

  .reports-table-container {
    z-index: 0;
    overflow: auto;
  }

  .reports-header-row {
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .report-filters {
    margin-top: -20px;
    @include md {
      margin-top: 0;
    }
    flex-wrap: wrap;
    background-color: var(--primary);
    padding: 20px;

    .report-date {
      margin-right: 15px;
      font-size: 14px;
      color: var(--primaryText);

      input,
      select {
        &::placeholder {
          color: var(--primaryText);
        }

        margin-bottom: 10px;
        @include md {
          margin-bottom: 0;
        }
        margin-top: 5px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid var(--primaryText);
        border-radius: 5px;
        background-color: var(--primary);
        color: var(--primaryText);
      }
    }

    button {
      font-size: 33px;
      padding: 10px;
      background-color: var(--success);
      color: var(--alternateText);
      border: none;
      flex-basis: 100%;
      margin: 20px 0 0 0;
    }

    @include md {
      button {
        flex-basis: unset;
        align-self: normal;
        width: 60px;
        font-size: 33px;
        padding: 10px;
        margin: -20px -20px -20px 0;
      }
    }
  }

  .table-reconciliation {
    width: 100%;
    margin-bottom: 40px;

    td,
    th {
      font-size: 10px;
      vertical-align: middle;
    }

    thead {
      background-color: var(--primary);

      * {
        font-weight: 700 !important;
      }

      th {
        vertical-align: middle;
        padding: 10px;
        color: var(--primaryText);

        &.commission {
          background-color: var(--primaryLighten);
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px 10px;

          &.commission {
            background-color: #fcffdc;
            font-weight: bold;
          }
        }

        &:nth-child(2n) {
          background-color: lightgrey;

          td {
            &.commission {
              background-color: darken(#fcffdc, 5%);
            }
          }
        }

        &:hover {
          cursor: pointer;
          background-color: gray;
          color: var(--alternateText);

          td.commission {
            color: var(--secondaryText);
          }
        }
      }
    }
  }
}

.notification-center {
  .dismiss-all {
    background-color: var(--primaryText);
    text-align: center;
    border: 1px solid black;
    padding: 5px 10px;
    font-size: 12px;
    margin-top: 10px;
    float: right;
    border-radius: 5px;
  }

  .notification-badge {
    position: relative;
    font-size: 20px;
    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: var(--danger);
      font-size: 8px;
    }
  }

  .notifications {
    left: 0;
    right: 0;
    margin-top: 10px;
    position: absolute;
    border-radius: 10px;
    padding: 10px;
    z-index: 10;

    .notification {
      background-color: #e6e7e8;
      padding: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: 10px solid transparent;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &.info {
        border-color: var(--info);
      }

      &.success {
        border-color: var(--success);
      }

      &.alert {
        border-color: var(--danger);
      }

      &.warning {
        border-color: var(--warning);
      }

      h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
      }

      * {
        color: var(--secondaryText);
        text-decoration: none;
      }

      .notification-title {
        &:hover {
          color: lighten(black, 30%);
        }
      }

      p {
        font-size: 10px;
      }
    }
  }
}

.confirm-box,
.react-confirm-alert-body {
  width: 400px;
  background-color: var(--primary);
  color: var(--primaryText);
  padding: 20px;
  z-index: 200;
  line-height: 20px;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  button {
    float: left;
    border-radius: 0;
    padding: 5px 10px;
    font-size: 16px !important;
    color: var(--alternateText);
    border: none;
    margin-top: 10px;

    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}

button {
  &.confirm {
    background-color: var(--success);
  }

  &.cancel {
    background-color: var(--danger);
  }
}

.react-confirm-alert-button-group {
  button:first-of-type {
    background-color: var(--success);
  }

  button:last-of-type {
    background-color: var(--danger);
  }
}

.table-ticket {
  thead {
    tr {
      border-bottom: 1px solid var(--primaryText);
    }
  }

  th {
    font-weight: 700;
  }

  td,
  th {
    text-align: left;
    padding: 5px;
    color: var(--primaryText);
  }
}

.table-history {
  td,
  th {
    padding: 2px;
  }
}

.rule-content {
  box-shadow: 0 0 5px 1px white;
}

.rule-box {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: normal;
  font-family: $fonts;

  strong {
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }

  .rule-header {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary);

    h1 {
      font-size: 20px;
      margin-left: 10px;
      font-weight: bold;
    }

    button {
      color: var(--alternateText);
      font-size: 30px;
      border: 0;
      background-color: transparent;
    }
  }

  .rule-description {
    width: 500px;
    background-color: var(--primary);
    padding: 10px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    font-size: 10px;
    line-height: 14px;
  }
}

.not-found-message {
  color: lighten(gray, 40%);
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.inline-search {
  width: 150px;

  input {
    padding: 5px;
    border: 1px solid var(--secondaryText);
    border-radius: 5px;
    width: 100%;
  }
}

.pnr-header-icon {
  font-size: 22px !important;
  margin-right: 5px;
}

.table-sales {
  margin-top: 30px;

  td,
  th {
    vertical-align: middle;

    &:not(.budget-cell) {
      padding: 10px;
      color: var(--primaryText);
    }
  }

  .budget-cell {
    padding: 0 10px;
  }

  th {
    &:first-of-type {
      width: 300px;
    }

    &:not(:first-of-type) {
      background-color: #0e1c34;
      font-weight: bold;
    }
  }

  tr {
    &.hidden {
      display: none;
    }

    &.depth-0 {
      background-color: lighten(#0e1c34, 10%);
    }

    &.depth-1 {
      background-color: lighten(#0e1c34, 20%);

      td {
        &:first-of-type {
          padding-left: 25px;
        }
      }
    }

    &.depth-2 {
      background-color: lighten(#0e1c34, 30%);

      td {
        &:first-of-type {
          padding-left: 40px;
        }
      }
    }

    &.depth-3 {
      background-color: lighten(#0e1c34, 40%);

      td {
        &:first-of-type {
          padding-left: 55px;
        }
      }
    }
  }
}

.progress-bar {
  border: 1px solid white;
  height: 23px;
  width: 100%;
  background-color: var(--success);
}

.order {
  margin: 10px 20px;
  padding: 10px;
  background-color: var(--primary);
  border-radius: 10px;
  overflow: hidden;
  border: 4px solid transparent;

  p {
    color: var(--primaryText);
  }
}

.order-details {
  font-size: 13px;
  color: var(--primaryText);
  text-decoration: none;

  span {
    margin-left: 5px;
  }
}

.basket-item-footer {
  margin-top: 5px;
  margin-bottom: -10px;
  margin-left: -10px;
  align-items: stretch;

  .price-tag {
    border-bottom-left-radius: 0 !important;
  }
}

.basket-item-status {
  font-weight: bold;
  color: var(--secondaryText);
  margin: 0 10px 5px 0;
  text-align: right;
}

.voucher {
  .is-bold {
    margin-right: 5px;
  }
}

.search-area {
  .day {
    font-size: 23px;
    margin: 0;
  }

  .month {
    font-size: 13px;
    margin: 0;
  }

  .from,
  .to {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .city-name {
      margin-bottom: 5px;
    }
  }

  .trip-dates {
    min-height: 56px !important;
  }
}

.order-list {
  .date {
    width: 35px;
    height: 42px;

    .fs-med {
      font-size: 13px;
    }

    .fs-small {
      font-size: 10px;
    }
  }

  .pnr-status {
    .pending {
      color: var(--warning);
    }

    .approved {
      color: var(--success);
    }

    .rejected {
      color: var(--danger);
    }
  }
}

.order-detailed {
  .travel-reason {
    display: flex;
    align-items: flex-end;
    color: var(--primaryText);
    padding: 10px 0 0 10px;

    span {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }

    @include md {
      span {
        font-size: 14px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .date {
    width: 36px;
    height: 44px;
  }

  .price-tag {
    padding: 10px;
  }

  button:not(.order-action-button):not(.clean-button) {
    width: 50px !important;
    height: 25px !important;
    margin-bottom: 5px;
    margin-top: 0 !important;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  .clean-button {
    padding: 0;
  }

  .box {
    margin: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .fs-lrg {
      font-size: 18px;
    }

    .fs-med {
      font-size: 16px;
    }

    .fs-std {
      font-size: 12px;
    }

    .fs-small {
      font-size: 10px;
    }

    .fs-note {
      font-size: 8px;
    }

    .fs-prc {
      font-size: 15px;
    }
  }
}

.quick-filter {
  .quick-filter-button {
    outline: 0;
    height: 28px;
    width: 28px;
    padding: 5px;
    background-color: transparent;

    * {
      color: var(--primary) !important;
    }

    border-radius: 100%;
    border: none;

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    &.active {
      border: 1px solid var(--primary);
    }

    &:last-of-type {
      margin-right: 5px;
    }
  }
}

.booking-details {
  h1 {
    margin-bottom: 5px;
  }

  label {
    margin: 0 !important;
  }

  *:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .booking-details-items {
    label {
      display: block;
    }
  }

  .booking-details-comment {
    textarea {
      width: 100%;
    }
  }

  .payment-footer {
    margin-right: -20px;

    .booking-next {
      color: var(--alternateText);
      border: 0;
      background-color: var(--success);
      float: right;
      line-height: 26px;
      font-size: 16px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .booking-next {
    //color: white;
    //border: 0;
    //background-color: var(--success);
    //float: right;
  }
}

.export-excel {
  color: var(--primaryText);
  background-color: #00aa21;
  border: 0;
  padding: 10px;
  float: right;

  span {
    margin-left: 5px;
  }
}

.pnr-list-scroll-area {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  @include smonly {
    > div.details {
      height: calc(100%);
    }
    > div {
      background-color: var(--boxBg);
      margin-bottom: 10px;
    }
  }
}

.pnr-container {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pnr-actions {
  flex-shrink: 0;
  display: flex;
  padding: 15px 10px 0;
  flex-wrap: wrap;
  @include md {
    padding: 20px 20px 10px;
  }

  button:not(:last-of-type) {
    margin-right: 10px;
  }

  button:not(.cancel):not(.confirm) {
    margin-bottom: 10px;
    padding: 10px;
    color: var(--alternateText);
    background-color: var(--primary);
    font-weight: 700;
    @include md {
      margin-right: 10px;
      padding: 0 10px;
      height: 32px;
    }
    border: 0;
    border-radius: 5px;

    &:hover {
      background-color: var(--primaryDarken);
    }
  }
}

.offers-container {
  margin-top: 30px;
  position: relative;
  overflow: hidden;

  &.cars {
    height: 220px;

    .content {
      height: 100%;
    }
  }
}

.offer-customers {
  .offer-customer {
    @include smonly {
      overflow-x: scroll;
    }
    padding: 10px;
    background-color: var(--primary);
    border-radius: 10px;
    margin-bottom: 10px;

    * {
      color: var(--primaryText);
    }
  }
}

.offer {
  color: var(--primaryText);
  background-color: var(--primary);
  margin-bottom: 10px;
  width: 240px;
  min-height: 128px;
  margin-left: -208px;
  transition: margin-left 0.3s ease-in-out;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  overflow: hidden;

  .offer-content {
    width: 100%;
  }

  .offer-image {
    height: auto;
    width: 40px;
    margin-right: 30px;
  }

  &.active {
    margin-left: 0 !important;
  }

  .offer-title {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    width: 16px;
    margin: 10px;

    h1 {
      cursor: pointer;
      white-space: nowrap;
      font-size: 16px;
      line-height: 1;
    }
  }

  .offer-description {
    margin: 10px 0 0 10px;
    font-size: 12px;
  }

  .offer-international {
    margin-left: 10px;
    font-size: 10px;
  }

  //.offer-icon {
  //  flex-shrink: 0;
  //  margin-right: 32px;
  //  opacity: .03;
  //  width: 104px;
  //  text-align: center;
  //
  //  * {
  //    font-size: 80px;
  //  }
  //}

  .offer-actions {
    margin-top: 10px;

    .price-tag {
      border: 0;
      background-color: var(--danger);
      color: var(--alternateText);
      padding: 5px;
      font-size: 13px;
      width: 64px;
      text-align: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-bottom: 5px;
    }

    .add-basket-button {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .source {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  &.has-bg {
    .offer-description,
    .offer-international,
    .source {
      margin: 0;
      padding: 3px 3px 3px 7px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.add-basket-button {
  border: 0;
  background-color: var(--primaryBgDarken);
  width: 40px;
  color: var(--alternateText);
  padding: 5px;
}

.cars {
  .add-basket-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .car-info {
    display: flex;
    align-items: center;

    .car-model {
      margin-right: 5px;

      p {
        font-size: 12px;
      }
    }
  }

  .offer {
    width: 486px;
    margin-left: -450px;
  }

  .offer-content {
    width: 450px;
  }
}

.voucher-link {
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  font-size: 18px;
  color: var(--primary);
}

.busy-screen {
  cursor: not-allowed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.airline-title {
  font-size: 10px;
}

.basket-gutter {
  width: 0;
  height: 100%;

  &.is-active {
    width: 400px;
  }
}

.fullpage {
  min-height: 0;

  .page {
    height: 100%;
    display: flex;
    flex-direction: column;

    &:not(.current) {
      display: none;
    }
  }

  height: 100%;
  width: 100vw;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.fullpage-controller {
  display: none;
  @include md {
    display: block;
  }
  position: fixed;
  top: 50%;
  right: 10px;
  padding: 5px;
  background-color: rgba($primaryBg, 0.3);
  border-radius: 20px;

  .controller {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    font-size: 25px;
    line-height: 30px;
    overflow: hidden;
    color: var(--primaryBg);

    &:after {
      content: "⚪";
    }

    &.current {
      &:after {
        content: "⚫";
      }
    }

    &.navigation {
      &.up {
        &:after {
          content: "▲";
        }
      }

      &.down {
        &:after {
          content: "▼";
        }
      }
    }
  }
}

.pnr-flight-logo {
  height: 30px;
  margin-right: 5px;
}

.offer-line-height {
  line-height: 21px;
}

.confirmation-send {
  width: 50px;
  margin-right: -20px;
  float: right;
  border: none;
  color: var(--primaryText);
  font-size: 20px;
  background-color: var(--success);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  .form-section {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    &:not(:last-of-type):not(:only-of-type) {
      margin-right: 5px;
    }

    &:not(:first-of-type):not(:only-of-type) {
      margin-left: 5px;
    }
  }

  .form-item {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--primaryText);
  }

  .remove-form-item,
  .add-form-item {
    padding: 0;
    background-color: transparent;
    border: 0;
    color: var(--primaryText);
    font-size: 25px;
  }

  .remove-form-item {
    width: 20px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    border-radius: 20px;
    background: var(--primaryText);
    color: var(--primaryBg);
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &.half {
      width: calc(50% - 5px);
    }

    &.horizontal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > * {
        input {
          width: 100%;
        }
      }
      > *:not(:last-child) {
        margin-right: 10px;
      }

      .form-sub-group {
        flex: 1;
      }
    }

    input,
    textarea {
      padding: 5px;
      border: none;
    }

    label {
      margin-bottom: 5px;

      input,
      select,
      textarea {
        width: 100%;
        min-height: 26px;
        margin: 0 !important;
      }

      span.label {
        font-weight: 700;
        margin-bottom: 3px;
      }
    }
  }
}

.form-errors {
  .error {
    display: inline-block;
    background-color: var(--danger);
    color: var(--primaryText);
    padding: 3px;
  }
}

.hidden {
  display: none;
}

.airport-location {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.airport-search {
  width: 100%;

  input {
    color: var(--primaryText);
    width: 100%;
    height: 30px;
    border: none;
    font-size: 23px;
    line-height: 30px;
    background-color: var(--primary);

    &::placeholder {
      color: var(--secondaryTextLighten);
    }
  }
}

.date-picker {
  position: absolute;
  top: 54px;
  width: 280px;
  z-index: 10;

  &.range {
    width: 560px;
  }
}

.modal-container.customers {
  z-index: 999999;

  .modal-body {
    width: 500px;
  }

  .customer {
    line-height: 16px;
    cursor: pointer;
    padding: 10px;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--primaryText);
    }

    .checkbox-simple {
      margin-right: 10px;
      font-size: 16px;
    }
  }

  .customer-search {
    position: relative;

    input {
      color: var(--primaryText);
      border: 1px solid var(--primaryText);
      border-radius: 20px;
      background-color: transparent;
      font-size: 14px;
      padding: 10px 45px 10px 15px;
    }

    button {
      font-size: 24px;
      background-color: transparent;
      color: var(--alternateText);
      position: absolute;
      border: 0;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .submit {
    float: right;
    margin-right: -20px;
  }
}

.order-items-list {
  padding-top: 0;
  overflow-x: hidden;
  @include smonly {
    background-color: transparent;
    margin: 0;
  }
  @include md {
    height: calc(100% - 80px);
  }

  .description {
    line-height: 17px;
  }
}

.fill-height {
  height: 100%;
}

.pull-right {
  float: right;
}

.hotel {
  .hover {
    cursor: pointer;

    .hover-mask {
      z-index: 1;
      display: none;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      color: var(--primaryText);
    }

    &:hover {
      .hover-mask {
        display: flex;
      }
    }
  }
}

.extended-infos {
  display: none;
}

.hotel-details-screen {
  position: relative;
  display: flex;
  overflow: hidden;

  .hotel {
    height: 195px;
    cursor: pointer;
  }

  .place-search {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 10;
    height: 30px;

    input {
      padding: 0 10px;
      height: 100%;
    }
  }

  .list {
    flex-shrink: 0;
    max-width: 350px;
    max-height: 100%;
    overflow: hidden;

    &.room-details {
      margin-top: -20px;
      max-width: unset;
      width: 435px;
      display: flex;
      flex-direction: column;
      max-height: calc(100% + 20px);

      .room-details-list-header {
        display: flex;
        align-items: center;
        background-color: var(--primary);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 285px;
        height: 20px;
        flex-shrink: 0;

        h1 {
          margin-left: 10px;
          color: var(--primaryText);
        }
      }

      .room-details-list-items {
        flex-grow: 1;
        overflow-y: auto;
        direction: rtl;
      }

      * {
        direction: ltr;
      }
    }

    &.hotel-details {
      display: flex;
      flex-direction: column;
      padding: 20px 0 20px 20px;
      background-color: rgba(#59585b, 0.8);
      border-radius: 20px;
      width: 400px;
      height: calc(100%);
      overflow: visible;

      .filter-box {
        top: 0;
        left: 0;
        width: 600px;
      }

      .title {
        flex-shrink: 0;
        padding-right: 10px;
        padding-bottom: 20px;
      }

      .info {
        .name {
          margin-bottom: 10px;
          max-width: 170px;
        }
      }

      .result-list {
        overflow-y: auto;
      }

      .details-refundable {
        color: var(--success);
        font-size: 10px;
      }
    }

    .price-tag-box {
      position: absolute;
      top: 20px;
      right: 0;
    }

    .price-tag-details {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: var(--priceTagWithOpacity);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      min-width: 50px;
      padding: 3px;
      color: var(--alternateText);
      font-weight: 700;

      &.standalone {
        font-size: 14px;
        height: 25px;
        min-width: 60px;
      }

      + p {
        margin: 0 auto;
        text-align: center;
        width: 60px;
        color: var(--priceTagWithOpacity);
        font-size: 8px;
      }
    }

    .hotel-container {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .box {
        border-radius: 0;
        margin-bottom: 0;
      }

      .hotel {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &.is-selected {
        .hotel {
          background-color: var(--secondaryBg);
        }
      }
    }
  }
}

.self-flex-end {
  align-self: flex-end;
}

.collapsable {
  position: relative;
  z-index: 1;

  button {
    pointer-events: auto;
  }

  .title {
    height: 68px;
    display: flex;
    align-items: center;
  }

  &.has-background {
    background-color: var(--primaryBg);
  }

  .collapse-button {
    border: none;
    background-color: transparent;
    font-size: 20px;
    height: 26px;
    color: var(--secondaryText);
  }

  .items {
    max-height: calc(100% - 88px);
  }

  &.collapsed {
    overflow: visible;

    .collapse-button {
      position: absolute;
    }

    .items {
      overflow: hidden !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    &.horizontal {
      .items {
        width: 0 !important;
      }
    }

    &.vertical {
      .items {
        height: 0 !important;
      }
    }
  }
}

.details-room-box {
  position: relative;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 3px;
  }
}

.details-room {
  padding-bottom: 10px;
  width: 300px;
  background-color: var(--secondaryBg);
  flex-shrink: 0;

  .details-room-header {
    position: relative;
    padding-top: 15px;
    padding-left: 10px;

    .room-type {
      font-size: 12px;
      color: var(--secondaryText);
      margin-bottom: 5px;
    }

    .allotment {
      color: var(--primaryText);
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;
      font-style: italic;

      span {
        padding: 0 2px;
        font-size: 16px;
      }
    }

    .tags {
      margin: 10px 0;
      line-height: 1.2;

      span {
        float: right;
        font-weight: 700;
      }

      .non-refundable {
        color: var(--danger);
      }

      .refundable {
        color: var(--success);
      }
    }

    .room-price {
      min-width: 120px;
      font-size: 20px;
      text-align: center;
      color: var(--alternateText);
      background-color: var(--danger);
      padding: 5px 10px;
    }
  }

  .details-room-body {
    padding: 10px;

    .amenities {
      margin: 0;

      * {
        font-size: 10px;
      }
    }

    p {
      margin-bottom: 5px;
    }
  }

  .details-room-footer {
    padding: 0 10px;

    * {
      color: var(--primaryText);
    }
  }

  &.selected {
    .allotment {
      color: var(--primaryText);
    }

    .cancellation {
      p {
        color: var(--primaryText) !important;
      }
    }

    .details-room-footer {
      * {
        color: var(--primaryText);
      }
    }

    .price-tag-details {
      background-color: var(--danger);
    }

    background-color: var(--primaryBg);

    .room-type {
      color: var(--primaryText);
    }
  }
}

.room-boards {
  cursor: pointer;
  margin: 0 15px 10px 15px;

  .details-room-board {
    display: flex;
    align-items: center;

    .cancellation {
      width: 75px;
      margin-left: 5px;
      text-align: center;

      p {
        font-style: italic;
        font-size: 8px;
        line-height: 10px;
        color: var(--secondaryText);
      }
    }

    &.not-available {
      position: relative;
      cursor: not-allowed;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: "";
        background-color: rgba(black, 0.3);
      }
    }

    .info {
      width: 100%;
      background-color: var(--primaryText);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid var(--primaryText);
      margin-bottom: 2px;

      .board-type {
        margin-left: 5px;
        color: var(--priceTagText);
        font-weight: 700;
      }

      p {
        font-size: 11px;
      }

      .price-tag-board {
        width: 60px;
        margin: -2px -2px -2px 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: var(--priceTagWithOpacity);
        align-items: center;
        justify-content: flex-end;
        padding: 6px 8px 6px;
        flex-shrink: 0;
        display: flex;
        color: var(--alternateText);
      }
    }

    &.selected {
      .info {
        border: 2px solid var(--danger);

        .price-tag-board {
          background-color: var(--danger);
        }
      }
    }
  }
}

.room-action-button {
  height: 30px;
  line-height: 30px;
  border: none;
  font-size: 16px;
  color: var(--alternateText);

  &.add-cart {
    background-color: var(--primaryBgDarken);
  }

  &.direct-booking {
    background-color: var(--success);
  }
}

.__react_component_tooltip {
  z-index: 10000;
}

.countdown-clock {
  color: var(--primaryText);
  font-weight: 700;
  font-size: 20px;
  margin-left: 10px;
}

.countdown-message {
  padding: 0 10px;
}

.hotel-details-map-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.google-map-place-search {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 300px;
  height: 30px;
  margin-right: 30px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
}

.room-list {
  height: 100% !important;

  .collapse-button {
    color: var(--primary);
  }
}

.distance {
  text-decoration: none;

  &.place {
    color: var(--distance);
  }

  svg {
    margin-right: 3px;
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }
}

.sorter {
  z-index: 1;
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  h3,
  p {
    color: var(--primaryText);
  }

  h3 {
    position: relative;
    overflow: hidden;
    width: 90px;
    font-size: 14px;
    padding: 0 0 5px 10px;
    border-bottom: 2px solid var(--primaryText);

    &:after {
      position: absolute;
      bottom: 0;
      right: -6px;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--primaryText);
    }
  }

  .sorter-list {
    position: absolute;
    margin-top: -2px;
    border: 2px solid var(--primaryText);
    width: 100%;
    padding: 5px;
    background-color: var(--primaryBg);

    .sorter-item {
      font-size: 12px;
      padding: 5px;
    }
  }
}

.numeric-input {
  width: 100px;
}

.weather {
  * {
    color: var(--primaryText);
  }

  img {
    width: 70%;
    margin-bottom: 5px;
  }

  line-height: 2px;
  display: inline-block;
  padding: 15px;
  background-clip: padding-box;
  margin: 10px 10px 0 10px;
  background-color: var(--boxBg);
  border-radius: 10px;

  .forecast-city {
    margin-bottom: 17px;
  }
}

.inline-report-action {
  text-align: center;

  button {
    height: 35px;
    width: 80%;
    border: 0;
    font-size: 8px;
    padding: 0;
    color: var(--alternateText);

    &.invoice {
      background-color: var(--danger);
    }

    &.collection {
      background-color: var(--info);
    }

    &.supplier {
      white-space: normal;
      background-color: var(--warning);
    }
  }
}

.report-actions {
  display: flex;

  button {
    padding: 10px;
    border: 0;
    color: var(--alternateText);

    &.invoice {
      background-color: var(--danger);
    }

    &.collection {
      background-color: var(--info);
    }

    &.supplier {
      background-color: var(--warning);
    }
  }

  .fill-space {
    flex-grow: 1;
  }

  .report-actions-title {
    text-decoration: underline;
    align-self: center;
    margin-right: 10px;
  }
}

.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.login-page-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #e7e8e9, #50565a 100%) no-repeat;

  .login-form {
    position: relative;
    width: 100%;
    padding: 0 50px;
    margin: 0 10px;
    background-color: #bcbec0;
    border: 2px solid #e9e9e9;
    border-radius: 10px;
    @include sm {
      padding: 0 70px;
      width: 400px;
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      height: 100px;
      content: "";
      display: block;
      border-radius: 50%;
      background: black;
      opacity: 0.3;
      filter: blur(25px);
      transform: scale(1, 0.2);
    }

    .login-header {
      .login-icon {
        color: var(--alternateText);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 20px auto;

        img {
          height: 100%;
          width: auto;
        }
      }

      .login-title {
        font-size: 16px;
        text-align: center;
        width: 100%;
        display: block;
        padding: 10px;
        background-color: #e9e9e9;
        border-radius: 20px;
        color: var(--alternateText);
        margin: -30px auto 25px auto;
        height: 60px;

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .login-body {
      .input {
        display: flex;
        width: 100%;
        margin: 0 auto;

        &:first-of-type {
          margin-bottom: 10px;
        }

        padding: 5px;
        background-color: var(--alternateText);
        border-radius: 10px;

        span {
          border-right: 2px solid #0e1c34;
          font-size: 18px;
          width: 35px;
          text-align: center;
          padding: 7px 0;
          flex-shrink: 0;
        }

        input {
          border: none;
          padding: 0 10px;
          font-size: 13px;
          width: 100%;
        }
      }

      .forgot {
        margin-top: 2px;
        padding-right: 10px;

        button {
          display: block;
          float: right;
          font-weight: 700;
          color: #0e1c34;
          text-decoration: none;
          font-size: 8px;
        }

        &:after {
          display: block;
          content: "";
          clear: both;
        }
      }
    }

    .login-footer {
      margin-bottom: 15px;

      .login-button {
        font-size: 14px;
        width: 100%;
        height: 42.5px;
        line-height: 42.5px;
        border: none;
        border-radius: 10px;
        color: var(--alternateText);
        background-color: #0e1c34;
        text-align: center;
      }
    }
  }
}

.lang-select {
  margin: 30px 0;
  position: relative;

  .lang {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 5px;
      font-weight: 700;
    }

    color: #0e1c34;
    margin: 0 auto;
  }
}

.lang-dropdown {
  position: absolute;
  width: 100%;
  margin-top: 5px;

  p {
    text-align: center;
    margin: 0 auto;
    width: 90px;
    padding: 5px;
    color: var(--primaryText);
    font-weight: 700;
    background-color: #0e1c34;
    border: 1px solid var(--primaryText);

    &:hover {
      color: #0e1c34;
      background-color: var(--primaryText);
    }

    &:first-of-type {
      border-bottom: none;
    }

    &:last-of-type {
      border-top: none;
    }
  }
}

.multiple {
  cursor: pointer;
  max-height: 30px;

  .multiple-icon {
    width: 5px;
  }
}

.exchange-rate {
  position: absolute;
  margin-left: 4px;

  p {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    color: var(--primary);

    &:not(:last-of-type) {
      &:after {
        height: 100%;
        content: "";
        display: inline-block;
        border-right: 2px solid black;
        margin: 0 5px;
      }
    }
  }

  table {
    thead {
      border-bottom: 1px solid var(--primaryText);
    }

    td {
      padding: 5px;
    }
  }
}

.custom-select {
  position: relative;
  font-size: 10px;

  > p {
    text-decoration: underline;

    &:after {
      font-size: 7px;
      content: " ▼";
      display: inline;
    }
  }

  .custom-select-options {
    width: 100%;
    background-color: var(--primary);
    position: absolute;
    z-index: 1;

    p {
      padding: 5px;

      &:hover {
        color: var(--primary);
        background-color: #fff;
      }
    }
  }
}

.map-content {
  pointer-events: none;
  position: absolute;
  padding: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.details-payment {
  display: flex;
  align-items: center;
  margin: 20px 0;

  &:before {
    content: "";
    height: 100%;
    border-right: 15px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: var(--primary);
  }

  .controllers {
    .price-tag {
      &:not(.has-edit) {
        width: 100px;
      }

      &.has-edit {
        padding: 5px;
        justify-content: space-between;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}

.hotel-details-infos {
  height: 100%;
  width: 500px;
  max-width: 100%;
  background-color: var(--secondaryBg);
  padding: 10px;
  //overflow-x: hidden;
  //overflow-y: auto;
  .table {
    @include smonly {
      td {
        padding: 0;
        vertical-align: middle;
        //font-size: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  position: relative;

  .hotel-meta-infos {
    @include md {
      display: flex;
      p {
        &:not(:last-of-type) {
          margin-right: 5px;
        }
      }
    }
  }

  .hotel-details-title {
    position: absolute;
    right: 10px;
    top: -45px;
    background: var(--primary);
    padding: 10px;
    border-radius: 10px;

    * {
      font-size: 16px;
      color: var(--primaryText);
    }
  }

  * {
    font-size: 10px;
    line-height: 14px;
    color: #231f20;
  }

  h1 {
    color: var(--primaryText);
    font-size: 12px;
    font-weight: bold;
  }

  > * {
    margin-bottom: 10px;
  }

  table {
    margin-left: -2px;
    margin-right: -2px;

    td {
      padding: 0 2px;
    }
  }
}

.hotel-surroundings {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: 48%;
  }
}

.hotel-info-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -5px;
}

.thumbnail {
  flex-shrink: 0;
  overflow: hidden;

  > * {
    display: block;
    height: 100%;
    width: 100%;
  }

  > div {
    background-size: cover;
    background-position: center;
  }
}

.double-flight-title {
  display: flex;
  padding: 0 20px;
  margin-bottom: 10px;

  h1 {
    width: 50%;
    font-weight: 700;
    color: var(--primaryText);
  }
}

.payment-page {
  .payment-module {
    padding: 10px;
    background-color: var(--primary);

    * {
      color: var(--primaryText);
    }
  }
}

.hotel-details {
  pointer-events: auto;

  .items {
    pointer-events: none;
  }
}

.details-tab {
  pointer-events: none;
  display: flex;
  align-items: flex-start;

  .room-details,
  .hotel-details-infos,
  .details-payment {
    pointer-events: auto;
  }
}

.hotel-details-date {
  padding: 10px;
  border-radius: 10px;
  margin-left: 100px;
  background-color: var(--primary);
  color: var(--primaryText);
}

.infoBox {
  > img {
    display: none !important;
  }

  &:hover {
    z-index: 10;
  }
}

.map-info-window {
  cursor: pointer;
  width: 220px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  background-color: var(--primary);

  .hotel-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hotel-name {
    padding: 0 5px;
    flex-grow: 1;
  }

  .hotel-price {
    flex-shrink: 0;
    margin-right: -10px;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: var(--danger);
    color: var(--alternateText);
  }

  &.small,
  &.medium {
    .thumbnail {
      display: none;
    }
  }

  &:not(:hover).small {
    background-color: transparent;
    padding: 0;

    .hotel-info {
      justify-content: flex-start;
    }

    .hotel-price {
      border-radius: 5px;
      float: left;
    }

    .hotel-name {
      display: none;
    }

    .stars {
      display: none;
    }
  }

  &:hover {
    .thumbnail {
      display: block;
    }

    .fill-space {
      order: 3;
      flex-grow: 1;
    }

    p {
      font-size: 13px;
    }

    .hotel-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .stars {
      order: 2;
    }

    .hotel-name {
      padding: 0;
      order: 1;
      flex-grow: 0;
      margin-bottom: 5px;
    }

    .hotel-price {
      order: 3;
      align-self: flex-end;
    }
  }
}

.pnr-refundable {
  margin-right: -85px;
  cursor: pointer;

  p {
    text-align: right;
    line-height: 15px !important;

    &.success {
      color: var(--success);
    }

    &.danger {
      color: var(--danger);
    }
  }
}

.booking-actions {
  margin-top: 5px;
}

.booking-details-modal {
  max-width: 400px;

  .booking-details-item {
    margin-bottom: 15px;
  }
}

.cancellation-message {
  p {
    margin-bottom: 5px;
  }
}

.policy-details {
  .policy-detail {
    margin-bottom: 10px;

    h4 {
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 10px;
      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
    .policy-item-title {
      font-size: 13px;
    }
    > div {
      margin-bottom: 5px;
    }
  }
}

.distances {
  margin-right: -20px;
}

.multi-switch {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;

  &.payment {
    background-color: var(--primaryBg);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    button {
      font-size: 9px;
      color: var(--alternateText);

      &.selected {
        color: var(--secondaryText);
        background-color: var(--alternateText);
      }
    }
  }

  button {
    font-weight: 700;
    border: 0;
    background-color: transparent;
    padding: 10px;
  }
}

.content-fixer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.car-mask {
  width: 100%;
  height: 300px;
  right: 0;
  left: 0;
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 10% 100%);
  position: absolute;
  background: rgba(black, 0.6);
}

.car-item {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;

  .price-tag {
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .car-vendor-logo {
    height: 50px;
    width: 50px;

    img {
      height: 50px;
      width: auto;
    }
  }
}

.payment-error-buttons {
  margin-top: 10px;

  button {
    border: 0;
    padding: 10px;
    margin-right: 5px;
    color: var(--alternateText);

    &.retry {
      background-color: var(--success);
    }

    &.gohomepage {
      background-color: var(--danger);
    }
  }
}

.pnr-items {
  position: relative;
  background-color: var(--primary);
  border-radius: 10px;

  .pnr-items-title {
    display: flex;
    margin-bottom: 10px;
    color: var(--primaryText);
    font-size: 20px;
    text-decoration: underline;

    h1 {
      width: 50%;

      &:last-of-type {
        margin-left: 40px;
      }
    }
  }
}

.approve-budget {
  p {
    color: var(--primaryText);
  }

  .budget {
    font-size: 18px;
    @include md {
      font-size: 24px;
    }
    padding: 5px;
    background-color: var(--success);
    font-weight: 700;
    border-radius: 5px;
    color: var(--alternateText);
  }

  .budget-details {
    display: flex;
    justify-content: space-between;
    @include smonly {
      padding: 10px;
      flex-direction: column;
    }

    @include md {
      > div:not(.separator) {
        padding: 10px;
        width: calc(50% - 20px);
      }
    }

    .until-spent {
      display: flex;
      justify-content: space-evenly;
      @include smonly {
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;
        @include md {
          font-size: 24px;
        }
        font-weight: 700;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          font-size: 30px;
        }
      }
    }

    .total-spent {
      p {
        font-size: 10px;
        @include md {
          font-size: 14px;
        }

        span {
          font-size: 16px;
          @include md {
            font-size: 20px;
          }
          font-weight: 700;
          margin-left: 5px;
        }
      }
    }
  }

  .separator {
    margin: 20px 0;
    width: 0;
    border-right: 1px solid var(--primaryText);
  }

  .customer-budget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .customer-budget-title {
      .customer-name {
        font-weight: 700;
        align-self: center;
      }
    }
  }

  .department-budget {
    .budget-summary {
      font-size: 24px;
      @include md {
        font-size: 28px;
      }

      span {
        font-size: 10px;
        @include md {
          font-size: 12px;
        }
      }
    }

    .budget-bar {
      margin: 10px 0;
      height: 40px;
      width: 100%;
      background-color: var(--primaryLighten);
      position: relative;

      i {
        padding-right: 10px;
        height: 100%;
        background-color: var(--secondary);
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      span {
        display: flex;
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        align-items: center;
        font-size: 30px;
        font-weight: 700;
        color: var(--primaryText);
      }
    }
  }
}

.approve-module {
  .approve-container {
    @include smonly {
      flex-direction: column;
      align-items: flex-start;
    }
    padding: 10px;
    border-radius: 10px;
    background-color: var(--primary) !important;
    align-items: center;

    .approve-icon {
      @include smonly {
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 16px;
      }
      @include md {
        margin: 0 10px;
      }

      font-size: 40px;
      color: var(--primaryText);
    }
  }

  .compliances {
    line-height: 18px;

    p {
      font-size: 12px;
      @include md {
        font-size: 14px;
      }
    }
  }

  .pnr-flight {
    @include smonly {
      border-radius: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--primaryText);
    }
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;
  }

  .route-items {
    width: 100%;
    @include md {
      width: 70%;
    }
  }

  .price-summary {
    @include md {
      padding-left: 10px;
      border-left: 1px solid var(--alternateText);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .pnr-dest {
  }

  .box-container {
    max-width: unset;
  }

  .list {
    @include md {
      width: 30%;
    }
  }

  .details {
    flex-grow: 1;
    @include md {
      width: 65%;
    }
  }

  table {
    td,
    th {
      padding: 10px;
      text-align: left;
    }

    th {
      font-weight: 700;
    }
  }
}

.offer-totals {
  display: flex;

  .offer-icon {
    opacity: 0.8;
    font-size: 30px;
    min-width: 68px;
    width: 100%;
    padding: 4px;
    text-align: center;
  }

  .offer-total {
    &:not(:last-of-type) {
      margin-right: 5px;
    }

    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .total-title {
      font-weight: 700;
      font-size: 16px;
      padding: 0;
    }
  }
}

button:disabled:not(.ril__zoomOutButton, .ril__zoomInButton) {
  cursor: not-allowed;
  background: repeating-linear-gradient(
    45deg,
    lighten(#3a3a3c, 20%),
    lighten(#3a3a3c, 20%) 4px,
    #3a3a3c 4px,
    #3a3a3c 8px
  ) !important;
}

.trip-reasons {
  padding: 0 20px;
  width: 100%;

  .trip-reason {
    h3 {
      font-size: 12px;
      margin-bottom: 3px;
    }

    margin-bottom: 10px;

    select,
    input,
    textarea {
      min-height: 26px;
      font-size: 12px;
      padding: 5px;
      border: none;
      width: 100%;
    }

    &.accounting-actions {
      position: relative;

      button {
        display: flex;
        align-items: center;
        outline: 0;
        padding: 0;
        color: var(--primaryText);
        font-size: 20px;
        background: none;
        border: none;
        margin-bottom: 5px;

        span {
          margin-left: 5px;
        }

        &:active {
          color: gray;
        }
      }

      .accounting-actions-list {
        > div {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.reason-select-box {
  input {
    display: none;
  }

  &.custom-is-active {
    input {
      display: unset;
    }

    select {
      display: none;
    }
  }
}

.booking-confirm {
  .booking-header {
    font-size: 16px;
    text-align: center;
    display: block;
    padding: 20px;
    background-color: #0e1c34;
    color: var(--alternateText);
    margin: -50px auto 20px auto;
  }

  position: relative;
  width: 400px;
  padding: 20px;
  background-color: #bcbec0;
  border: 2px solid var(--primaryText);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .input-group {
    width: 100%;
    display: flex;
    align-items: center;

    p {
      background-color: white;
      height: 100%;
      padding: 10px;
      border: 1px solid gray;
    }
  }

  input {
    padding: 10px;
    flex-grow: 1;
    border: 1px solid gray;
    border-left: 0;
  }

  button {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    height: 50px;
    //background-color: var(--primary);
    color: white;
    font-weight: 700;
    border: 1px solid white;

    &:disabled {
      cursor: not-allowed;
      background: gray !important;
      color: white;

      &.success {
        background: var(--success) !important;
      }
    }
  }

  .success-message {
    text-align: center;

    strong {
      margin: 5px 0;
      display: block;
      font-weight: 700;
    }
  }
}

.passenger-count {
  p:not(:last-of-type) {
    margin-right: 10px;
  }

  h2 {
    font-size: 16px;
    text-decoration: underline;
    line-height: 20px;
  }
}

.continue-booking {
  width: unset !important;
}

.checkout-container {
  z-index: 99999;
}

.rate-comments {
  max-width: 500px;
  margin-bottom: 20px;
}

.app-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  pointer-events: fill;

  &.has-mask {
    background: black;
    opacity: 0.3;
  }
}

.homepage-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover !important;
  background-position: center !important;
  z-index: -1;
}

.activity-modal-body {
  @include smonly {
    display: block !important;
  }
}
.pickup-dropoff {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: var(--secondaryText);
  p {
    line-height: 14px;
    width: 40%;
    &:last-of-type {
      text-align: right;
    }
  }
}
.activity-container {
  height: 220px;

  .card {
    margin-bottom: 0 !important;
  }

  .address {
    margin-bottom: 10px;
  }

  .activity-modal-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .activity-description {
    overflow: auto;

    &:not(.no-padding) {
      padding: 20px;
    }

    p {
      line-height: 14px;
    }

    strong {
      line-height: 16px;
    }

    .price-tag {
      padding: 10px;
      border-radius: 10px;
    }
  }

  .activity-map-container {
    width: 500px;
    flex-shrink: 0;

    > div {
      height: 100%;
      width: 100%;
      position: unset !important;
      z-index: unset !important;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.page-footer {
  background-color: var(--primary);
  @include smonly {
    display: none;
  }

  p {
    color: var(--primaryText);
    font-size: 10px;
  }

  img {
    height: 100%;
    width: auto;
  }

  .footer-container {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 50px;
    max-width: 1900px;
    margin: 0 auto;
  }
}

.table-search {
  margin-bottom: 10px;
  margin-left: 0;
  position: relative;
  width: 200px;

  input {
    border: 1px solid var(--primaryBg);
    font-size: 16px;
    border-radius: 15px;
    padding: 5px 10px;
  }

  .search-icon {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.edit-room {
  label {
    font-size: 14px;
  }

  input {
    margin-top: 4px;
    font-size: 20px;
  }
}

.car-amenities {
  display: block;
  @include md {
    display: flex;
    flex-wrap: wrap;
    > .car-amenity {
      width: 50%;
    }
  }
}

.car-card {
  @include smonly {
    .dates {
      position: absolute;
    }
    .car-thumbnail {
      margin-top: 40px;
    }
  }
}

.car-details-cover {
  min-height: 90px;
  position: relative;

  .car-details-picture {
    display: block;
    width: 100%;
    height: auto;
  }

  .car-details-vendor {
    position: absolute;
    max-height: 50px;
    width: auto;
    right: 0;
    bottom: 0;
  }
}

.car-description-group {
  > * {
    margin-bottom: 30px;
  }
}

.currency-select {
  pointer-events: auto;
  box-sizing: border-box;
  padding: 1px;
  font-size: 14px;
  border: 1px solid var(--primaryText);
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--primaryText);
  margin-bottom: 0;
}

.provision-confirm {
  background-color: var(--promotion);
  color: white;
  padding: 5px;
  border: none;

  &:disabled {
    background-color: var(--success);
  }
}

.filter-choices {
  display: flex;

  .filter-choice {
    cursor: pointer;
    padding: 5px;
    border: 1px solid black;
    margin-right: 3px;
    min-width: 35px;
    text-align: center;

    &.selected {
      color: var(--alternateText);
      background-color: var(--primaryBg) !important;
    }
  }
}

.search-time-picker {
  margin-left: 15px;
}

.time-select {
  font-size: 14px;
  color: var(--primaryText);
  display: flex;
  align-items: center;
  margin-top: 5px;

  input,
  select {
    cursor: pointer;
    outline: 0;
    border: none;

    &::placeholder {
      color: var(--primaryText);
    }

    margin-bottom: 10px;
    @include md {
      margin-bottom: 0;
    }
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--primary);
    color: var(--primaryText);
  }
}

.cancellation-table {
  th {
    font-weight: 700;
    text-align: center;
    font-size: 13px;
  }

  td {
    text-align: left;
    font-size: 14px;
  }

  th,
  td {
    vertical-align: middle;
    border: 1px solid white;
    padding: 10px;
    max-width: 300px;
  }
}

.provision-notes {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 5px;
  }
}

.checker {
  padding: 0;
  display: flex;
  border: none;
  background-color: transparent;
  color: var(--primaryText);
  align-items: center;
  font-size: 16px;

  p {
    text-align: left;
    margin-left: 5px;
  }
}

.mobile-tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--secondary);
  flex-shrink: 0;
  height: 40px;

  button {
    color: var(--primaryText);
    font-weight: 700;
    width: 50%;
    border: none;
    background-color: transparent;
    height: 100%;

    &.is-active {
      background-color: var(--primary);
    }
  }
}

.is-tablet-only {
  @include smonly {
    display: none !important;
  }
}

.is-mobile-hidden {
  @include smonly {
    display: none;
  }
}

.is-mobile-only {
  display: none;
  @include smonly {
    display: block;
  }
}

.program-list-table {
  td {
    padding: 5px;
  }
}

.program-search-button {
  border: 0;
  color: var(--primaryText);
  background-color: var(--success);
  padding: 5px;
}

.program-container {
  padding-left: 100px;
}

.program-item-time {
  width: 100px;
  padding-bottom: 5px !important;
}

.program-item-title {
  font-size: 10px !important;
  padding-bottom: 5px !important;
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    margin-left: 4px;
  }
}

.program-item-description {
  padding-left: 10px;
  font-size: 10px !important;
  padding-bottom: 15px !important;
}

.program-link {
  margin-left: 5px;
  font-size: 13px;
  border: 0;
  background-color: var(--warning);
  padding: 5px;
  text-decoration: none;
  color: var(--primaryText);
}

.program-details-container {
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);

  h3 {
    margin-bottom: 10px;
    border-bottom: 1px solid black;
    font-size: 13px;
  }

  .program-item-time {
    font-size: 12px;
  }

  > table {
    margin-top: 10px;
  }

  .program-details-title {
    user-select: none;
    cursor: pointer;
    font-weight: bold;
  }
}

.x-sell-section {
  position: absolute;
  right: -120px;
  overflow: hidden;
  width: 120px;
  .x-sell-item-container {
    width: 40px;
    overflow: visible;
    .x-sell-item {
      border: none;

      padding: 10px !important;
      background-color: var(--primary);
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      width: 120px;
      margin-left: -80px;
      transition: margin-left 0.2s ease-in-out;
      color: var(--primaryText);
      svg {
        font-size: 22px;
      }
      span {
        font-weight: bold;
      }
      &:hover {
        margin-left: 0;
      }
    }
  }
}

.x-sell-button {
  &:disabled {
    cursor: not-allowed;
    background-color: #6f6f6f !important;
  }
}

.pnr-timelimit {
  text-align: right;
  color: var(--danger);
  font-weight: bold;
}

.offer-airitinprice {
  cursor: pointer;
  p {
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
}

.phone-field {
  display: flex;
  .phone-code {
    text-align: right;
    width: 42px !important;
    margin-right: 1px;
  }
}
.field {
  width: 100%;
  display: block;
  input,
  span {
    display: block;
  }
}

.customer-miles {
  select {
    display: inline-block;
  }
}

.CalendarDay {
  vertical-align: middle;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover, .CalendarDay__selected_span {
  background: rgba($warning,.4);
  border: 1px double rgba($warning,.4);
  color: var(--textPrimary);
  &:hover {
    background: rgba($warning,.6);
    border: 1px double rgba($warning,.6);

  }
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: $warning;
  border: 1px double $warning;
}
.DateInput,
.DateRangePickerInput_arrow {
  display: none !important;
}

.DateRangePicker_picker,
.SingleDatePicker_picker {
  top: 0 !important;
  left: 0 !important;
  font-size: 0.5rem;
  // transform-origin: top left !important;
  // transform: scale(0.8) perspective(1px) translate3d(0, 0, 0) !important;
}

.date-picker-box {
  left: 0;
  bottom: -20px;
  font-family: $fonts;
  z-index: 10;
  &.hidden {
    display: none !important;
  }
  &.absolute {
    position: absolute;
    .DateRangePicker_picker {
      position: absolute;
    }
  }
  &:not(.absolute) {
    .DateRangePicker_picker {
      position: unset;
    }
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 2;
}
